import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link";

export const DoctorCard = ({ data, specialistId='' }) => {
    let x = Math.round(Math.random() * 12);
    return (
        <div className="single-team-member">
            <HashLink to={`/doctors/${data.PartyCode}/${specialistId !== '' ? `?specialistId=${specialistId}` : ''}`}>
                <div className="img-holder">
                    <img src={`./assets/img/doctors/${x}.jpg`} alt="Awesome Image" style={{height: 'auto', minHeight: '14rem'}}/>
                    <div className="overlay-style">
                        <div className="box">
                            <div className="content">
                                <div className="top">
                                    <h3>{data.Name}</h3>
                                    <span>{data.SpecialistDesc}</span>
                                </div>
                                <span className="border"></span>
                                <div className="bottom">
                                    <ul>
                                        <li><i className="fa fa-phone" aria-hidden="true"></i> {data.RegMob1}</li>
                                        <li><i className="fa fa-envelope" aria-hidden="true"></i> {data.StateDesc}</li>
                                    </ul>    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-holder">
                        <h3>{data.Name}&nbsp;</h3> 
                        <span>{data.SpecialistDesc}&nbsp;</span>   
                    </div>    
                </div>
            </HashLink>
        </div>
    )
}

export const ServicesCard = ({ data }) => {

    const item = data.img.split('.')[0];

    return (
        <div className="single-team-member">
            <Link to={data.link ? `/services/${data.link}` : '#'}>
                <div className="img-holder">
                    <img src={`./assets/img/BSN/${data.img}`} alt="Awesome Image"/>
                    <div className="overlay-style">
                        <div className="box">
                            <div className="content">
                                <div className="top">
                                    <h3>{data.name}</h3>
                                </div>
                                <span className="border"></span>
                                <div className="bottom">
                                    {/* <ul>
                                        <li><i className="fa fa-phone" aria-hidden="true"></i> {data.phone}</li>
                                        <li><i className="fa fa-envelope" aria-hidden="true"></i> {data.mail}</li>
                                    </ul>     */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-holder">
                        <h3>{data.name}</h3>   
                    </div>    
                </div>
            </Link>
        </div>
    )
}

export const DepartmentCard = ({ data }) => {
    return (
        <div className="single-item text-center">
            <div className="icon-holder">
                <span className="material-symbols-outlined">{data.icon}</span>
            </div>
            <div className="text-holder">
                <h3>{data.title}</h3>
                <p>{data.content}</p>
            </div>
            <Link className="readmore my-btn-1" to={`services/${data.link}`}>Read More</Link>
        </div>
    )
}

export const TestimonialCard = ({ data }) => {
    return (
        <div className="single-testimonial-item text-center">
        <div className="img-box">
            <div className="img-holder">
                <img src={`./assets/img/${data.img}`} alt="Awesome Image"/>
            </div>
            <div className="quote-box">
                <i className="fa fa-quote-left" aria-hidden="true"></i>    
            </div>
        </div>
        <div className="text-holder">
            <p>{data.review}</p>
        </div>
        <div className="review-stars" style={{margin: '0.4em 0 0.1em'}}>
            <i className='bx bxs-star'></i>
            <i className='bx bxs-star'></i>
            <i className='bx bxs-star'></i>
            <i className='bx bxs-star'></i>
            <i className='bx bxs-star-half' ></i>
        </div>
        <div className="name">
            <h3>{data.name}</h3>
            <span>{data.place}</span>
        </div>
        </div>
    )
}

export const HealthPackageCard = () => {
    return (
        <div className="health-pakage">
            <div className="floating-label">
                56% off
            </div>
            <div className="review-stars">
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star-half' ></i>
            </div>
            <h3>BASIC COMPREHENSIVE PACKAGE (TOTAL 58 PARAMETERS)</h3>
            <p className="package-content">Including GLUCOSE FASTING, ESR, COMPLETE BLOOD COUNT(CBC) <span>+more</span></p>
            <p className="home-collection"><i className='bx bxs-home'></i> Home collection available</p>
            <div className="price-box">
                ₹5920 <span>₹2399</span>
            </div>
            <div className="btn-box">
                <a className="readmore my-btn-1 btn-solid" href="#/departments/cardiology">Read More</a>
            </div>
        </div>
    )
}

export const BedCategorie = ({ data }) => {

    const item = data.img.split('.')[0];

    return (
        <div className="single-team-member">
            <Link to={data.link ? `/departments/${data.link}` : '#'}>
                <div className="img-holder bedCatagories">
                    <img src={`./assets/img/BSN/${data.img}`} alt="Awesome Image"/>
                    <div className="overlay-style">
                        <div className="box">
                            <div className="content">
                                <div className="top">
                                    <h3>{data.name}</h3>
                                </div>
                                <span className="border"></span>
                                <div className="bottom">
                                    {/* <ul>
                                        <li><i className="fa fa-phone" aria-hidden="true"></i> {data.phone}</li>
                                        <li><i className="fa fa-envelope" aria-hidden="true"></i> {data.mail}</li>
                                    </ul>     */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-holder">
                        <h3>{data.name}</h3>   
                    </div>    
                </div>
            </Link>
        </div>
    )
}
export const SocialActivityCard = ({ data }) => {

    const item = data.img.split('.')[0];

    return (
        <div className="single-team-member">
            <Link to={data.link ? `/departments/${data.link}` : '#'}>
                <div className="img-holder">
                    <img src={`./assets/img/Service/social/${data.img}`} alt="Awesome Image"/>
                    {/* <div className="overlay-style">
                        <div className="box">
                            <div className="content">
                                <div className="top">
                                    <h3>{data.name}</h3>
                                </div>
                                <span className="border"></span>
                                <div className="bottom">
                                    <ul>
                                        <li><i className="fa fa-phone" aria-hidden="true"></i> {data.phone}</li>
                                        <li><i className="fa fa-envelope" aria-hidden="true"></i> {data.mail}</li>
                                    </ul>    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-holder">
                        <h3>{data.name}</h3>   
                    </div>     */}
                </div>
            </Link>
        </div>
    )
}