import { connect } from "react-redux";
import InvoiceDefault from "./default";


const Invoices = ({ match }) => {

    const id = match.params.id;        

    if (!id) return;
    return navigateToPrint(id);

}

const mapStateToDashboard = (state) => {
    return { userInfo: state.userInfo, isLoggedIn: state.isLoggedIn }
}

export default connect(mapStateToDashboard, {})(Invoices);





const navigateToPrint = (id) => {
    // switch(type) {
    //   case '':
    //     return;
    //   default:                                        
        return <InvoiceDefault id={id} />;
    // }
}