import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SliderSection from './sliderSection';
import { CategoryCard, ConnectedProductCard, ConnectedProductCard1, BannersCard, BrandsCard } from './cards';
import { SliderSectionM } from './mobileView/homeM';
import { ConnectedUpdateScroll, ControlledCarousel, escape, MySlider, scrollPage, Spinner } from './utilities';
import { connect } from 'react-redux';
import { breadCrumbAction, filterCategoryAction, globalDataAction, modalAction, siteDataAction } from '../../../actions';
import { TAKE_HOME_ID, XYZ_ID } from '../../../constants';


function Home({ breadCrumbAction, compCode, filterCategoryAction, siteData, siteDataAction, isMobile, globalDataAction, modalAction, globalData }) {    
    
  useEffect(() => {
    breadCrumbAction({links: [{name: 'Home', link: '/'}], activeLink: '/'});
    if (siteData.isLoading) {
      setProductsData({loading: true, data: {itemMasterCollection: [], ParentCategoryList: [{Value: ''}], LinkSubCategoryList: [], ItemBrandList: []}, err: {status: false, msg: ''}});
    } else {
      setProductsData({loading: false, data: siteData, err: {status: false, msg: ''}});
    } 
  },[breadCrumbAction, siteData])

  const takeHome_banner_images = ['/assets/img/ePharma/bg-banner/slide-1.png', '/assets/img/ePharma/bg-banner/slide-2.png', '/assets/img/ePharma/bg-banner/slide-3.png'];
  const default_banner_images = ['/assets/img/ePharma/bg-banner/Final-03.jpg', '/assets/img/ePharma/bg-banner/Final-03.jpg', '/assets/img/ePharma/bg-banner/Final-03.jpg','/assets/img/ePharma/bg-banner/Final-03.jpg'];

  const [productsData, setProductsData] = useState({loading: true, data: {itemMasterCollection: [], ParentCategoryList: [{Value: ''}], LinkSubCategoryList: []}, err: {status: false, msg: ''}});

  const renderCategories = (data) => {
    if (data.loading) {
      return <Spinner min_height='19rem' fSize='1.5rem'/>;
    } else if (data.err.status) {
      return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
    } else if (data.data.ParentCategoryList.length === 0) {
      return <div className='p-4'>No Categories found!</div>;
    } else {
      return data.data.ParentCategoryList.map((item, index) => {
        const subItemsList = data.data.LinkSubCategoryList.filter(i => item.Value === i.Parent.toString());
        return (
            <div className="card border-0 mb-0" key={index}>
                <div className="card-header d-flex align-items-center bg-white" id={`${item.Value}`} style={{borderBottom: '1px solid #4d9db3', padding: '.83rem 1.25rem'}}>
                    <Link to={`/filters/?head=${item.Text}&catVal=${item.Value}&page=1`} onClick={() => filterCategoryAction('selectedCategoryId', item.Value)} className="mb-0 text-dark">{item.Text}</Link>
                    <span className="btn btn-link ms-auto text-muted position-relative" data-toggle="collapse" data-target={`#collapse_${item.Value}`} aria-expanded="true" aria-controls={`collapse_${item.Value}`} style={{textDecoration: 'none !important'}}>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </div>
                <div id={`collapse_${item.Value}`} className="collapse" aria-labelledby={`${item.Value}`} data-parent="#cat-menu-accordion">
                    {
                    subItemsList.map((subItem, n) => {
                        return (
                            <div key={n} className="card-body" style={{marginBottom: "1px"}}>
                                <Link to={`/filters/?head=${item.Text}&catVal=${item.Value}&subHead=${subItem.CategoryDesc}&subCatVal=${subItem.CategoryId}&page=1`} style={{color: 'black', fontWeight: 'bold'}}>{subItem.CategoryDesc}</Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
      })
    }
  }

  const responsive = {
    0: {
      items: 2
    },
    550: {
      items: 2
    },
    768: {
      items: 3
    },
    992: {
      items: 4
    },
    1200: {
      items: 5
    },
	}

  const renderCategoriesSlider = (data) => {
    if (data.loading) {
      return <Spinner min_height='19rem' fSize='1.5rem'/>;
    } else if (data.err.status) {
      return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
    } else if (data.data.ParentCategoryList.length === 0) {
      return <div className='text-center my-5 w-100'><h2 className="text-info mark">No items found!</h2></div>;
    } else {
      return <SliderSection children={<CategoryCard />} data={data.data.ParentCategoryList} responsive={responsive} autoPlay={false} id="category-slider" />
    }
  } 

  let banners = [
    {id: 1, name: 'TAKE HOME HIGH QUALITY ALLOPATHIC  PRODUCTS', tagline: 'One pharmacy for the whole family',logo: '', button: 'ORDER NOW', img: 'logo-removebg-preview (1).png', clr: '#00a0c7'},
    {id: 2, name: 'DEVELOPED TO FULFIL ALL YOUR HEALTH NEEDS', tagline:'Up to 45% Off on Healthcare Products', button: 'SHOP NOW', img: 'medicine-order.1.png', clr: '#cb1d59'},
    {id: 3, name: 'MEDICATIONS AND MORE RIGHT TO YOUR DOOR', tagline:'Good times and great prices! for Healthcare Proucts', button: 'SHOP NOW', img: 'image-removebg-preview (11) 1.png', clr: '#36a50b'},
    {id: 4, name: 'GET MEDICINES AT THE BEST PRICE', tagline:'Our prices make your heart healthy too', button: 'SHOP NOW', img: 'image-removebg-preview (10) 1.png', clr: '#a510cb'},
  ]

  const responsive_banners = {                                           
    0: {
      items: 1
    },
    550: {
      items: 1
    },
    768: {
      items: 2
    },
    992: {
      items: 2
    },
    1200: {
      items: 3
    },
	}

  const colors = ['#59fff6', '#ffd145', '#FF9800', '#76e4ff', '#cddc39', '#ffe5c6', '#4eff4e', '#ff77a6', '#00a0c7'];
  const renderBannersSlider = (data) => {
    // if (data.loading) {
    //   return <Spinner min_height='19rem' fSize='1.5rem'/>;
    // } else if (data.err.status) {
    //   return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
    // } else if (data.data.ParentCategoryList.length === 0) {
    //   return <div className='text-center my-5 w-100'><h2 className="text-info mark">No items found!</h2></div>;
    // } else {
      return <SliderSection children={<BannersCard />} data={data} responsive={responsive_banners} autoPlay={false} id="category-slider" />
    // }
  }

  // let brands = [
  //   {id: 1, name: 'Wyeth Ltd.'},
  //   {id: 2, name: 'Pfizer Limited'},
  //   {id: 3, name: 'Abbott Healthcare Pvt. Ltd.'},
  //   {id: 4, name: 'USV Private Limited'},
  //   {id: 5, name: 'Bayer Zydus Pharma Pvt. Ltd.'},
  //   {id: 6, name: 'Sanofi India Limited.'},
  //   {id: 7, name: 'Torrent Pharmaceuticals Ltd.'},
  // ]

  const responsive_brands = {
    0: {
      items: 3
    },
    550: {
      items: 4
    },
    768: {
      items: 5
    },
    992: {
      items: 6
    },
    1200: {
      items: 7
    },
    1500: {
      items: 7
    },
	}

  const renderBrandsSlider = (data) => {
    if (data.loading) {
      return <Spinner min_height='19rem' fSize='1.5rem'/>;
    } else if (data.err.status) {
      return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
    } else if (data.data.ItemBrandList.length === 0) {
      return; // <div className='text-center my-5 w-100'><h2 className="mark">No Brands found!</h2></div>;
    } else {
      return <SliderSection children={<BrandsCard />} data={data.data.ItemBrandList} responsive={responsive_brands} autoPlay={false} id="category-slider" />
    }
  }

  const renderSlider = (data, parentId) => {
    const productCategoryItems = data.data.itemMasterCollection.filter(i => i.Category === parentId).slice(0, 20);   
    const parentCategoryName = data.data.ParentCategoryList.filter(i => i.Value === parentId.toString())[0]?.Text;
    const subLinks = data.data.LinkSubCategoryList.filter(i => parentId === i.Parent);
    if (data.loading) {
      return <Spinner min_height='39rem' fSize='2.5rem'/>;
    } else if (data.err.status) {
      return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
    } else if (productCategoryItems.length === 0) {
      return; // <div className='text-center my-5 w-100'><h2 className="text-secondary mark">No Product found!</h2></div>;
    } else {
      scrollPage('home', globalDataAction);
      if (isMobile) return <SliderSectionM data={productCategoryItems} parentCategoryName={parentCategoryName} parentId={parentId}/>;
      return (
        <section className="product-area li-laptop-product Special-product overflow-hidden mt-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="li-section-title d-flex justify-content-between">
                            <h2 className='me-4'>
                                {parentCategoryName}
                            </h2>
                            <ul className="li-sub-category-list d-none d-lg-flex gap-2 ms-auto me-4 text-nowrap overflow-hidden">
                                {subLinks.map((item, index) => {
                                    return (
                                      <li key={index}><Link to={`/filters/?head=${parentCategoryName}&catVal=${parentId}&subHead=${item.CategoryDesc}&subCatVal=${item.CategoryId}&page=1`}>{item.CategoryDesc}</Link></li>
                                    )
                                })}
                            </ul>
                            <Link className='text-primary text-nowrap' to={`/filters/?head=${escape(parentCategoryName).swap}&catVal=${parentId}`}>VIEW ALL</Link>
                        </div>
                        <div className="row">
                            <div className="special-product-active product-active">
                                <div className="col-lg-12 home-item pt-1 pb-5">
                                  {(() => {
                                    if (compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
                                      const productSlide = productCategoryItems.map(i => (<ConnectedProductCard1 data={i} key={i.ItemId} />));
                                      // return <SliderSection children={<ConnectedProductCard1/>} data={productCategoryItems} id="" />
                                      return <MySlider name={'product-slider'} responsive={responsive_4} dataList={productSlide} customSettings={{autoplay: false, infinite: false, dots: false}}/> 
                                    } else {
                                      return <SliderSection children={<ConnectedProductCard/>} data={productCategoryItems} id="" />;
                                    }
                                  })()}                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      )
    }
  }

  const responsive_4 = [
    { breakpoint: 2000, settings: { slidesToShow: 4 } },
    { breakpoint: 1200, settings: { slidesToShow: 3 } },
    { breakpoint: 1000, settings: { slidesToShow: 2 } },
    { breakpoint: 700, settings: { slidesToShow: 1 } }
  ]

  return (
    <>
      <div className="slider-with-banner pb-xs-5 pb-20">
        <div className="container">
            <div className="row flex-column-reverse flex-lg-row">
                <div className="col-lg-3 d-none d-lg-block">
                  {(() => {
                    if (compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
                      return (
                        <div className='row action-list g-2 pt-3 pt-lg-0'>
                          <div className='col col-6 col-lg-12'>
                            <div className="action-item" style={{'--clr': '#feeec8'}}>
                              <i className='bx bxs-package'></i>
                              <div className=''>
                                <h4>Your Order</h4>
                                <p className='mb-0'>Add products to your cart.</p>
                              </div>
                            </div>
                          </div>
                          <div className='col col-6 col-lg-12'>
                            <div className="action-item" style={{'--clr': '#ceeffe'}}>
                              <i className='bx bxs-shopping-bag'></i>
                              <div className=''>
                                <h4>Picking your order</h4>
                                <p className='mb-0'>Your order is being picked.</p>
                              </div>
                            </div>
                          </div>
                          <div className='col col-6 col-lg-12'>
                            <div className="action-item" style={{'--clr': '#d4f8c4'}}>
                              <i className='bx bx-gift'></i>
                              <div className=''>
                                <h4>Packing Your Order</h4>
                                <p className='mb-0'>We are packing your order</p>
                              </div>
                            </div>
                          </div>
                          <div className='col col-6 col-lg-12'>
                            <div className="action-item" style={{'--clr': '#d8dafe'}}>
                              <i className='bx bxs-truck' ></i>
                              <div className=''>
                                <h4>Deliver</h4>
                                <p className='mb-0'>Your order is out for delivery.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div className="category-menu category-menu-2">
                            <div className="category-heading">
                                <h2 className="categories-toggle"><i className="fas fa-th"></i><span>categories</span> <i className="fas fa-chevron-down ms-auto"></i></h2>
                            </div>
                            <div id="cate-toggle" className="category-menu-list">
                                <div id="cat-menu-accordion">
                                    {renderCategories(productsData)}
                                </div>
                            </div>
                        </div>
                      )
                    }
                  })()}
                </div>  
                <div className="col-lg-9 position-relative" id='home-banner'>
                    {(() => {
                      if (compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
                        return <ControlledCarousel data={takeHome_banner_images} interval={2000} controls={false} />;
                      } else {
                        return <ControlledCarousel data={default_banner_images} interval={2000} controls={false} />
                      }
                    })()}
                    <div className='presc-mini-box' onClick={() => modalAction('PRESCRIPTION_MODAL', true)}>
                      <div>
                        {globalData.prescription.name ? <i className='bx bxs-message-square-check'></i> : <i className="fas fa-cloud-upload-alt"></i>}
                        <div>
                          <span>{globalData.prescription.name ? 'Uploaded your' : 'Upload your'}</span>
                          <h4>Prescription</h4>
                        </div>
                      </div>
                    </div>
                </div>    
            </div>
        </div>
      </div>
      {(compCode === TAKE_HOME_ID || compCode === XYZ_ID) &&  <div className='cat-content'>
        <div className='featCat-sub-list container'>
        <div className="li-section-title"><h2>Categories</h2><ul className="li-sub-category-list d-none d-lg-block"></ul></div>
          {renderCategoriesSlider(productsData)}
        </div>
      </div>}
      {(compCode === TAKE_HOME_ID) &&  <div className='cat-content'>
        <div className='featCat-sub-list container'>
        <div className="li-section-title"><h2>Offers</h2><ul className="li-sub-category-list d-none d-lg-block"></ul></div>
          {renderBannersSlider(banners)}
        </div>
      </div>}
      <div>
        {(compCode === TAKE_HOME_ID || compCode === XYZ_ID) &&  <div className='cat-content brand-section'>
          <div className='featCat-sub-list container'>
            <div className="li-section-title mb-md-4"><h2>Brands</h2><ul className="li-sub-category-list d-none d-lg-block"></ul></div>
            {renderBrandsSlider(productsData)}
          </div>
        </div>}
      </div>
      {/* {compCode === TAKE_HOME_ID ? <div>
        <div className='mt-3 mb-0 mb-md-20 offerBnr'>
          <div className='d-flex justify-content-between  mw-100 align-items-center gap-3 flex-md-row flex-column pb-4 pb-md-0'>
              <div><img src="/assets/img/ePharma/offers/image-removebg-preview (7) 1.png" alt="" /></div>      
              <div>
                <h1>Amazing deals on buying medicines from us</h1>
                <h3>Get extra savings on your orders. Free shipping, same-day delivery and more...</h3>
              </div>
              <div className='banner p-0 me-auto me-md-0'><button style={{width: '10em', padding: '.5em 0'}}>KNOW MORE</button></div>      
          </div>
        </div>
      </div> : ''} */}
      <div>
        {productsData.data.ParentCategoryList.map((item, index) => (<div key={index} className={isMobile ? 'sliderContainer' : ''}>{renderSlider(productsData, parseInt(item.Value))}</div>))}
      </div>
      <ConnectedUpdateScroll page={'home'} />
    </>  
  );
}

const mapStateToProps = (state) => {
  return { compCode: state.compCode, siteData: state.siteData, isMobile: state.isMobile, globalData: state.globalData };
}

export default connect(mapStateToProps, {breadCrumbAction, filterCategoryAction, siteDataAction, globalDataAction, modalAction})(Home);

