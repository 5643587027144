const Services = () => {
    return (
        <section className="services position-relative">
            <div className="page-label-wrapper">
                <div className="page-label">
                    <h1>Departments</h1>
                    <ul className="list-inline">
                        <li><i className='bx bxs-home'></i> Home &nbsp;&nbsp;/</li>
                        {/* <li>Patient Care &nbsp;&nbsp;/</li> */}
                        <li>Departments &nbsp;&nbsp;</li>
                    </ul>
                </div>
            </div>
            <div className="">
                <div className="items-wrapper">
                    <div className="services-card">
                        <div className="item-img-box">
                            <img className="img-fluid" src="https://demo2.themewarrior.com/hospitalplus/wp-content/uploads/sites/22/2016/04/Nephrologist-Care-280x215.jpg" alt="item-1"/>
                        </div>
                        <div className="item-content-box">
                            <h2>Nephrologist Care</h2>
                            <p>Tell me why, I love you like I do. Tell me who, could stop my heart.</p>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="item-img-box">
                            <img className="img-fluid" src="https://demo2.themewarrior.com/hospitalplus/wp-content/uploads/sites/22/2016/04/Facility-Optic-280x215.jpg" alt="item-1"/>
                        </div>
                        <div className="item-content-box">
                            <h2>Eye Care</h2>
                            <p>Well we're movin' on up, to the east side. To a deluxe apartment in the sky.</p>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="item-img-box">
                            <img className="img-fluid" src="https://demo2.themewarrior.com/hospitalplus/wp-content/uploads/sites/22/2016/02/Depositphotos_11882261_original-280x215.jpg" alt="item-1"/>
                        </div>
                        <div className="item-content-box">
                            <h2>Pediatrician Clinic</h2>
                            <p>My kinda people, my kinda place. There's something special about this place. Got no reason to.</p>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="item-img-box">
                            <img className="img-fluid" src="https://demo2.themewarrior.com/hospitalplus/wp-content/uploads/sites/22/2016/04/prenatal-care-280x215.jpg" alt="item-1"/>
                        </div>
                        <div className="item-content-box">
                            <h2>Prenatal Care</h2>
                            <p>In an age when nature and magic rule the world, there is an extraordinary legend: the.</p>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="item-img-box">
                            <img className="img-fluid" src="https://demo2.themewarrior.com/hospitalplus/wp-content/uploads/sites/22/2015/02/service-11-280x215.jpg" alt="item-1"/>
                        </div>
                        <div className="item-content-box">
                            <h2>Medical Counseling</h2>
                            <p>Sometimes the world looks perfect, nothing to rearrange. Sometimes you just, get a feeling like you.</p>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="item-img-box">
                            <img className="img-fluid" src="https://demo2.themewarrior.com/hospitalplus/wp-content/uploads/sites/22/2015/02/Rehabilitation-280x215.jpg" alt="item-1"/>
                        </div>
                        <div className="item-content-box">
                            <h2>Rehabilitation Center</h2>
                            <p>Extraordinary crimes against the people and the state had to be avenged by agents extraordinary. Two.</p>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="item-img-box">
                            <img className="img-fluid" src="https://demo2.themewarrior.com/hospitalplus/wp-content/uploads/sites/22/2015/01/Eldery-Care-1-280x215.jpg" alt="item-1"/>
                        </div>
                        <div className="item-content-box">
                            <h2>Eldery Care</h2>
                            <p>What walks down stairs, alone or in pairs, and makes a slinkity sound? A spring, a.</p>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="item-img-box">
                            <img className="img-fluid" src="https://demo2.themewarrior.com/hospitalplus/wp-content/uploads/sites/22/2015/01/service-5-280x215.jpg" alt="item-1"/>
                        </div>
                        <div className="item-content-box">
                            <h2>Competitive Doctors</h2>
                            <p>In an age when nature and magic rule the world, there is an extraordinary legend: the.</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Services;