import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { loginStatusAction, userInfoAction, loaderAction } from '../../../../actions';
import { connect } from 'react-redux';
import { useFetch, ModalComponent, handleNumberInputs, customTabsButtons, createDate, getDuration, encrypt, JQDatePicker, MyModal } from '../utilities';
import axios from 'axios';
import { Accordion } from 'react-bootstrap';


const LoginModal = ({ modals, modalAction, compCode, isLoggedIn, loginStatusAction, userInfo, userInfoAction, loaderAction, bookingInfo }) => {

    const [statesList, setStatesList] = useState([{Description: 'West Bengal', CodeId: 3}]); 
  
    const history = useHistory();
    const modalOpen = modals.LOGIN_MODAL.state;
    const modalMode = modals.LOGIN_MODAL.data.mode;
  
    useEffect(() => {
      const getStates = async () => {
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Values/1`);
        loaderAction(false);
        let states = res.data.map(i => ({Description: i.Description, CodeId: i.CodeId}));
        setStatesList(states);
      }
      getStates();
    },[loaderAction])
  
    useEffect(() => {
      setEnteredOTP('');                         // Reset these field on whnever form opens or closes.
      setGeneratedOTP('init_random_otp');        // Initialize with random number to prevent submission with otp === ''.
    },[modalOpen])
  
    useEffect(() => {
      handleUserType(modalMode);
    },[modalMode])
    
    useEffect(() => {                             // RESET FORM - Close otp personal fields when user reopens the login modal.
      setShowNumberSubmitBtn(true);
      setShowOtpField(false);
      setLoginError({status: false, message: ''});                      // Reset loginError to false on every page reRender.
      setShowPersonalFields(false);
      setShowOrgFields(false);
    },[modalOpen])
  
    const [alertVisible, setAlertVisible] = useState(false);
    const [forgotPasswordModal, setForgotPasswordModal] = useState({isOpen: false, passwordRecoveryNumber: ''});
    const [refNumber, setRefNumber] = useState('');
    const [loginData, setLoginData] = useState({ phone: '', password: '', EncCompanyId: '' });
    const [registerData, setRegisterData] = useState({
      Salutation: '',
      Name: '',
      EncCompanyId: '',
      PartyCode: '',
      RegMob1: '',
      Gender: '',
      GenderDesc: '',
      Address: '',
      Age: '',
      AgeMonth: '',
      AgeDay: '',
      UserPassword: '',
      UserType: '',                // setting this to 'PATIENT' handleUserType()
      Qualification: '',
      RegNo: '',
      SpecialistId: 0,
      UserId: 0,
      PartyId: 0,
  
      State: '3',
      StateName: 'West Bengal',
      City: '',
      Pin: '',
      Address2: '',
  
      DOB: '',
      DOBstr: '',
      AnniversaryDate: '',
      AnniversaryDatestr: '',
      Aadhaar: '',
      IsDOBCalculated: 'N',
  
      compName: '',
      compAddress: '',
      compState: '',
      compPin: '',
      compPhone1: '',
      compPhone2: '',
      compMail: '',
    })
  
  
   const [genderData, genderDataLoading, genderDataError] = useFetch(`${process.env.REACT_APP_BASE_URL}/api/Values`, compCode);
   const [showOtpField, setShowOtpField] = useState(false);
   const [showPersonalFields, setShowPersonalFields] = useState(false);
   const [showOrgFields, setShowOrgFields] = useState(false);
   const [showNumberSubmitBtn, setShowNumberSubmitBtn] = useState(true);
   const [loginError, setLoginError] = useState({status: false, message: ''});
   const [generatedOTP, setGeneratedOTP] = useState('init_random_otp');
   const [enteredOTP, setEnteredOTP] = useState('asdfsdff');
   const [specializations, setSpecializations] = useState({isFieldOpen: false, data: []});
   const [isTabActive, setTabActive] = useState('register');
  
   useEffect(() => {
    if (!isLoggedIn) {                        
      // dumpAllLoginData();                                                                       // Currently Page reload on logout is making whole website reset, hence turning off for now.           
      // dumpAllRegisterData();                                                                    // Reset these data whenever sign in status changes. Clear all user data from the form when he logs out.  
      setLoginData(pre => ({...pre, EncCompanyId: compCode}));                                     // set company code on both forms.
      setRegisterData(pre => ({...pre, EncCompanyId: compCode}));          
    } else {
      setLoginData(preValue => {              // Populate the forms with existing user data if he is logged in.
        return { ...preValue, phone: userInfo.RegMob1, password: '', EncCompanyId: userInfo.EncCompanyId };
      });
      setRegisterData((pre => ({             
        ...pre,
        Name: userInfo.Name,
        RegMob1: userInfo.RegMob1,
        UserId: 0,
        UserType: userInfo.UserType,
        PartyCode: userInfo.PartyCode,
        EncCompanyId: userInfo.EncCompanyId,
        Age: userInfo.Age,
        AgeDay: userInfo.AgeDay,
        AgeMonth: userInfo.AgeMonth,
        Gender: userInfo.Gender,
        GenderDesc: userInfo.GenderDesc,
        MPartyCode: userInfo.MPartyCode,
        Address: userInfo.Address,
        Qualification: userInfo.Qualification,
        SpecialistDesc: userInfo.SpecialistDesc,
        RegNo: userInfo.RegNo,
        State: userInfo.State, 
        StateName: userInfo.StateName,                         
        City: userInfo.City,
        Pin: userInfo.Pin,
        Address2: userInfo.Address2,
        UHID: userInfo.UHID,
        MemberId: userInfo.MemberId,
        PartyId: userInfo.PartyId,
        Salutation: userInfo.Salutation,
  
        DOB: new Date(userInfo.DOB).toLocaleDateString('en-TT'),
        DOBstr: new Date(userInfo.DOB).toLocaleDateString('en-TT'),
        AnniversaryDate: new Date(userInfo.AnniversaryDate).toLocaleDateString('en-TT'),
        AnniversaryDatestr: new Date(userInfo.AnniversaryDate).toLocaleDateString('en-TT'),
        Aadhaar: userInfo.Aadhaar,
        IsDOBCalculated: userInfo.IsDOBCalculated,
  
        compName: userInfo.compName ? userInfo.compName : '',
        compAddress: userInfo.compAddress ? userInfo.compAddress : '',
        compState: userInfo.compState ? userInfo.compState : '',
        compPin: userInfo.compPin ? userInfo.compPin : '',
        compPhone1: userInfo.compPhone1 ? userInfo.compPhone1 : '',
        compPhone2: userInfo.compPhone2 ? userInfo.compPhone2 : '',
        compMail: userInfo.compMail ? userInfo.compMail : '',
      })))
    }
  }, [isLoggedIn, userInfo]);
  
   // LOGIN FORM FUNCTIONS..
  
   const handleLoginInput = (e) => {
     const {name, value} = e.target;
     setLoginData(preValue => {
       return {...preValue, [name]: value};
     });
   }
  
   const handleLoginFormSubmit = (e) => {
     e.preventDefault();
     makeLoginRequest({ phone: loginData.phone, password: loginData.password, companyCode: compCode });
   }
   
   const makeLoginRequest = async (params) => {
     loaderAction(true);
     const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth?UN=${params.phone}&UP=${params.password}&CID=${params.companyCode}`);
     loaderAction(false);
     if (res.data.Remarks === 'INVALID') {
       setLoginError({status: true, message: 'The username or password is incorrect.'});
     } else if (res.data.Remarks === 'NOTINCOMPANY') {
      setRegisterData((pre => ({             
        ...pre,
        Salutation: res.data.Salutation,
        Name: res.data.Name,
        EncCompanyId: res.data.EncCompanyId,
        PartyCode: '',
        RegMob1: res.data.RegMob1,
        Gender: res.data.Gender,
        GenderDesc: res.data.GenderDesc,
        Address: res.data.Address,
        Age: res.data.Age,
        AgeMonth: res.data.AgeMonth,
        AgeDay: res.data.AgeDay,
        // UserPassword: res.data.UserPassword,               // force to re-enter.
        // UserType: res.data.UserType,                       // set by modalMode
        Qualification: res.data.Qualification,
        RegNo: '',                                            // Receiving null.
        SpecialistId: res.data.SpecialistId,
        UserId: res.data.UserId,
        PartyId: res.data.PartyId,
        MemberId: res.data.MemberId,
    
        State: res.data.State,
        StateName: res.data.StateName,
        City: res.data.City,
        Pin: res.data.Pin,
        Address2: res.data.Address2,
    
        DOB: new Date(res.data.DOB).toLocaleDateString('en-TT'),
        DOBstr: new Date(res.data.DOB).toLocaleDateString('en-TT'),
        AnniversaryDate: new Date(res.data.AnniversaryDate).toLocaleDateString('en-TT'),
        AnniversaryDatestr: new Date(res.data.AnniversaryDate).toLocaleDateString('en-TT'),
        Aadhaar: '',                                        // Not required.
        IsDOBCalculated: 'N',

        UHID: res.data.UHID,
    
        compName: res.data.compName ? res.data.compName : '',
        compAddress: res.data.compAddress ? res.data.compAddress : '',
        compState: res.data.compState ? res.data.compState : '',
        compPin: res.data.compPin ? res.data.compPin : '',
        compPhone1: res.data.compPhone1 ? res.data.compPhone1 : '',
        compPhone2: res.data.compPhone2 ? res.data.compPhone2 : '',
        compMail: res.data.compMail ? res.data.compMail : '',
      })))
      setShowPersonalFields(true);
      setShowNumberSubmitBtn(false);                                                          // hide NEXT button of OTP verification.
      setEnteredOTP('verified');                                                              // pass OTP check at makeLoginReuest.
      setTabActive('register');
      setLoginError({status: false, message: ''});
     } else {
       let userLoginData = {
          Name: res.data.UserFullName,
          RegMob1: params.phone,
          UserId: res.data.UserId,
          UserType: res.data.UserType,
          PartyCode: res.data.PartyCode,
          EncCompanyId: params.companyCode,
          Age: res.data.Age,
          AgeDay: res.data.AgeDay,
          AgeMonth: res.data.AgeMonth,
          Gender: res.data.Gender,
          GenderDesc: res.data.GenderDesc,
          MPartyCode: res.data.MPartyCode,
          Address: res.data.Address,
          Qualification: res.data.Qualification,
          SpecialistDesc: res.data.SpecialistDesc,
          RegNo: res.data.RegNo ? res.data.RegNo : '',
          State: res.data.State, 
          StateName: res.data.StateName,                         
          City: res.data.City,
          Pin: res.data.Pin,
          Address2: res.data.Address2,
          UHID: res.data.UHID,
          MemberId: res.data.MemberId,
          PartyId: res.data.PartyId,
          Salutation: res.data.Salutation,
  
          DOB: res.data.DOB,
          DOBstr: res.data.DOB,
          AnniversaryDate: res.data.AnniversaryDate,
          AnniversaryDatestr: res.data.AnniversaryDate,
          Aadhaar: res.data.Aadhaar,
          IsDOBCalculated: res.data.IsDOBCalculated,
  
          compName: res.data.compName ? res.data.compName: '',
          compAddress: res.data.compAddress ? res.data.compAddress: '',
          compState: res.data.compState ? res.data.compState: '',
          compPin: res.data.compPin ? res.data.compPin: '',
          compPhone1: res.data.compPhone1 ? res.data.compPhone1: '',
          compPhone2: res.data.compPhone2 ? res.data.compPhone2: '',
          compMail: res.data.compMail ? res.data.compMail: '',
        };
  
        localStorage.setItem("userLoginData", encrypt({ phone: params.phone, password: res.data.UserPassword, compCode: params.companyCode }));
        userInfoAction(userLoginData);
  
        const newbookingData = { 
          ...bookingInfo,
          Salutation: res.data.Salutation,
          Name: res.data.Name,
          EncCompanyId: userInfo.selectedCompany.EncCompanyId,
          PartyCode: userInfo.selectedCompany.CompUserPartyCode,
          MPartyCode: userInfo.selectedCompany.CompUserMPartyCode,
          RegMob1: res.data.RegMob1,
          Gender: res.data.Gender ,
          GenderDesc: res.data.GenderDesc ,
          Address: res.data.Address ,
          Age: res.data.Age,
          AgeMonth: res.data.AgeMonth,
          AgeDay: res.data.AgeDay,
          State: res.data.State ,
          StateName: res.data.StateName,
          City: res.data.City,
          Pin: res.data.Pin,
          Address2: res.data.Address2,
          AnniversaryDate: res.data.AnniversaryDate,
          Aadhaar: res.data.Aadhaar ,
          UserId: res.data.UserId,
          UHID: res.data.UHID,
          MemberId: res.data.MemberId,
          Country: res.data.Country,
        }
        handleRedirect(res.data.UserType, newbookingData);
  
        modalAction('LOGIN_MODAL', false, { mode: res.data.UserType });
        loginStatusAction(true);
      }
   }
  
  
  
  //  const dumpAllLoginData = () => {
  //     setLoginData(preValue => {
  //       return {
  //         ...preValue,
  //         phone: '',
  //         password: '',
  //       }
  //     })
  //  }
  
    const handleRegistrationInput = (e) => {
      const {name, value} = e.target;
      setRegisterData(preValue => {
        return {...preValue, [name]: value};
      });
    }

    const handleStateDropdown = (id) => {
      let selectedState = statesList.find(i => i.CodeId === parseInt(id));
      setRegisterData(pre => ({ ...pre, State: selectedState.CodeId, StateName: selectedState.Description }));
    }

    const handleRegisterFormSubmit = async (e) => {
      e.preventDefault();
      console.log(registerData);
      if (registerData.UserPassword.length < 4) return alert('Please Enter a password of minimum 4 digits.');
      if (enteredOTP !== '' || enteredOTP === 'verified') {                                              // enteredOTP === generatedOTP. also put in in compareOtp. 
        let status = await makeRegisterationRequest(registerData);          // Using await to wait for final the result after complete running of the
          if (status) {                                                     // Waiting is required otherwise status === pending instead of true/false.
            // userInfoAction(registerData);                                // Asyncronous code (api request) present in the makeResiterationRequest function. 
            
            setTimeout(() => {
              makeLoginRequest({ phone: registerData.RegMob1, password: registerData.UserPassword, companyCode: compCode });     // wait to avoid api race condition.
            }, 500);

            // const newbookingData = { 
            //   ...bookingInfo,
            //   Salutation: userInfo.Salutation,
            //   Name: userInfo.Name,
            //   EncCompanyId: userInfo.selectedCompany.EncCompanyId,
            //   PartyCode: 0,
            //   MPartyCode: '',
            //   RegMob1: userInfo.RegMob1,
            //   Gender: userInfo.Gender,
            //   GenderDesc: userInfo.GenderDesc,
            //   Address: userInfo.Address,
            //   Age: userInfo.Age,
            //   AgeMonth: userInfo.AgeMonth,
            //   AgeDay: userInfo.AgeDay,
            //   State: userInfo.State,
            //   StateName: userInfo.StateName,
            //   City: userInfo.City,
            //   Pin: userInfo.Pin,
            //   Address2: userInfo.Address2,
            //   AnniversaryDate: userInfo.AnniversaryDate,
            //   Aadhaar: userInfo.Aadhaar,
            //   UserId: userInfo.UserId,                              // inserting UserInfo received in makeRegisterationRequest and stored userInfo.UserId.
            //   UHID: userInfo.UHID,
            //   MemberId: userInfo.MemberId,
            //   Country: 1,
            // }
  
            // handleRedirect(registerData.UserType, newbookingData);     
  
            // // handleRedirect(registerData.UserType, Object.assign(userInfo, registerData));     // Mannually concat both objects since userInfoAction is unable to update userInfo before passing
            //                                                                                   // it to the makeBookingRequest due to asyncronous updating nature of state update.
            // loginModalAction(false, modalMode);                                     // update userInfo here to avoid messing with makeBookingRequest.
            // loginStatusAction(true);
          } else {
            alert('An Error Occured, Please try again later.');
            return;
          }
      } else {
        alert('Wrong OTP, enter correct OTP');
      }
    }
  
    const handleRedirect = (userType, bookingData) => {
      switch (userType) {
        case 'PATIENT':
          if (!bookingData.TimeSlotId) {
            // history.push('/');
          } else {
            // makeBookingRequest(bookingData);        
            // setTimeout(() => { history.push('/dashboard') }, 1000);
          }
          break;
        default:
          history.push('/dashboard');
          break;
      }                                                    
    }
  
    const compareOtp = () => {
      
      if (enteredOTP !== '') {                            // enteredOTP === generatedOTP. also put in in handleRegisterFormSubmit.
        if (modalMode === "POLYCLINIC") {
          setShowOrgFields(true);
        } else {
          setShowPersonalFields(true);
        }
        setShowOtpField(false);
      } else {
        alert('Wrong OTP, enter correct OTP');
      }
    }
  
    const resendOtp = () => {
      revealOtpField();
    }
  
    const handleOrgSubmit = () => {
      const res = true;
      if (res) {
        setShowOrgFields(false);
        setShowPersonalFields(true);
      } else {
        console.log('unexpected error.');
      }
    }
  
    const makeRegisterationRequest = async (params) => {
      console.log(params);
      if (!params.UserType) return alert('Error, no user type received.');
      try {
        loaderAction(true);
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/UserReg`, params);
        loaderAction(false);
        if (res.data[0] === 'Y') {
          localStorage.setItem("userLoginData", encrypt({ phone: params.RegMob1, password: params.UserPassword, compCode: params.EncCompanyId }));
          userInfoAction({ ...params, UserId: parseInt(res.data[1]) });        // received UserId is string type hence converting it to integer because everywhere (received login data) else it's used as integer
          return true;                                                         // 'UserId' !== UserId which can cuase wrong output in filtering just like done in getMembersList function.
        }      
      } catch (err) {
        console.log(err);
        return false;
      }
    }  
  
    const dumpAllRegisterData = () => {
      setRegisterData(preValue => {
        return {
          ...preValue,
          Salutation: '',
          Name: '',
          // EncCompanyId: '',                  // Do not reset EncCompanyId which is compCode for the current user which we got from URL paramenters.
          PartyCode: '',
          RegMob1: '',
          Gender: '',
          GenderDesc: '',
          Address: '',
          Age: '0',
          AgeMonth: '0',
          AgeDay: '0',
          UserPassword: '',   
          UserType: modalMode, 
          Qualification: '',
          RegNo: '',
          SpecialistId: 0,
          State: '3',                           // taking string as select element returns value strings only.
          StateName: 'West Bengal',
          City: '',
          Pin: '',
          Address2: '',
          PartyId: 0,
  
          DOB: '',
          DOBstr: '',
          AnniversaryDate: '',
          AnniversaryDatestr: '',
          Aadhaar: '',
          IsDOBCalculated: 'N',
          UHID: '',
          MemberId: '',
  
          compName: '',
          compAddress: '',
          compState: '',
          compPin: '',
          compPhone1: '',
          compPhone2: '',
          compMail: '',
        }
      });
    }
  
    const makeOtpRequest = async () => {
      loaderAction(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserReg/0?name=Subscriber&mob=${registerData.RegMob1}`);
      loaderAction(false);
      if (res.status === 200) {
        setGeneratedOTP(res.data);
        console.log(`${res.data}`.split('').reverse().join(''));
        return;
      }
      alert('An Error Occured, Try again later.');
    }
  
    const toggleGender = (e) => {
      let val = e.target.value;
      let female = ['Ms', 'Mrs', 'Miss'];
      if (female.includes(val)) {
        setRegisterData(preValue => {
          return {...preValue, Gender: 105, GenderDesc: 'Female'};
        });
      } else {
        setRegisterData(preValue => {
          return {...preValue, Gender: 104, GenderDesc: 'Male'};
        });
      }
    }
  
    const handleFormClose = () => {
      modalAction('LOGIN_MODAL', false, {mode: modalMode});                    // Close the form.
      dumpAllRegisterData();                   // Dump all entered Register data.
    }
  
    const checkExistingUser = async () => {
      if (registerData.RegMob1.length > 9) {
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserReg?UN=${registerData.RegMob1}`);
        loaderAction(false);
        if (res.data === 'Y') {
          setLoginError({status: true, message: 'This number is already registered.'});
          setLoginData(preValue => {
            return { ...preValue, phone: registerData.RegMob1 }
          })
          setRegisterData(preValue => {
            return { ...preValue, RegMob1: '' }
          })
          setTabActive('login');
        } else {
          setLoginError({status: false, message: ''});
          revealOtpField();
          setShowNumberSubmitBtn(false);
        }
      }
    }
  
    const revealOtpField = () => {
      if (registerData.RegMob1.length > 9 && !loginError.status ) {
        setShowOtpField(true);
        makeOtpRequest(); 
      }
    }
  
    const showReference = () => {
      return <span className="text-info">Please keep your Reference(H) No: <span className='text-danger ms-2'>{refNumber}</span></span>;
    }
  
    const userTypes = ['POLYCLINIC', 'DOCTOR', 'PROVIDER', 'COLLECTOR'];
  
    const handleUserType = (item) => {
      setRegisterData((preValue) => {
        return {...preValue, UserType: item}
      });
      modalAction('LOGIN_MODAL', modalOpen, {mode: item});
      if (item === 'DOCTOR' || item === 'POLYCLINIC') {
        getSpecializations();
      } else {
        setSpecializations(preValue => {
          return {...preValue, isFieldOpen: false}
        });
      }
    } 
  
    const getSpecializations = async () => {
      loaderAction(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Values?CID=${compCode}`, {});
      loaderAction(false);
      if (res.data.length > 0) {
        setSpecializations({isFieldOpen: true, data: res.data});
      } else {
        setSpecializations({isFieldOpen: true, data: ['no data found']});
      }
    }
  
    const handleForgotPasswordForm = (e) => {
      e.preventDefault();
      sendPassword();
    }
  
    const sendPassword = async () => {
      loaderAction(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth/0?UN=${forgotPasswordModal.passwordRecoveryNumber}&CID=${compCode}&Type=FP`, {});
      loaderAction(false);
      if (res.data === 'Y') {
        setLoginError({status: true, message: 'Your Password has been sent to your registered mobile number. please check !'});
      } else {
        setLoginError({status: true, message: 'This number is not Registered.'});
      }
    }
  
    const ForgotPassword = () => {
      return (
        <form onSubmit={handleForgotPasswordForm}>
          <div className="row gx-2">
            <div className="col-12">
              <div className="form-group form-focus focused" id="forgotPassword">
                <label className="focus-label"><b className='text-danger'>* </b>Mobile Number</label>
                <input name="passwordRecoveryNumber" onChange={(e) => handleNumberInputs(e, setForgotPasswordModal)} value={forgotPasswordModal.passwordRecoveryNumber} required className="form-control floating" tabIndex={1} id="password_recovery_number" maxLength={10} />
              </div>
              <p className="text-danger" style={{display: loginError.status ? 'block' : 'none'}}>{loginError.message}</p>
              <button type="submit" className="d-block btn btn-primary ms-auto" style={{"minWidth": "9rem", "fontSize": "0.95rem"}}>GET PASSWORD</button>
            </div>
          </div>
        </form>
      )
    }
  
    const handleForgotPasswordModal = (item) => {
      setForgotPasswordModal({ isOpen: item, passwordRecoveryNumber: ''});
      setLoginError({status: false, message: ''});
    }
  
    const handleDate = (props) => {
      const { Age, AgeMonth, AgeDay, currField, currValue }  = props;
      let calculatedDOB;
      if (currField === 'Age') {
          calculatedDOB = createDate(AgeDay || 0, AgeMonth || 0, currValue);
      } else if (currField === 'AgeDay') {
          calculatedDOB = createDate(currValue, AgeMonth || 0, Age || 0);
      } else if (currField === 'AgeMonth') {
          calculatedDOB = createDate(AgeDay || 0, currValue, Age || 0);
      }
      setRegisterData(pre => ({...pre, DOB: calculatedDOB, DOBstr: calculatedDOB, IsDOBCalculated: 'Y'}));
    }
  
    const handleNumberInputsWithDate = (e, setStateName) => {
      const {name, value} = e.target;
      const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
        setStateName(preValue => {
           return {...preValue, [name]: value};
        });
        let currValues = { Age: registerData.Age, AgeMonth: registerData.AgeMonth, AgeDay: registerData.AgeDay, currField: name, currValue: value };
        handleDate(currValues);
      }
    }
  
    const calculateDuration = (date) => {
      setRegisterData(pre => ({ 
          ...pre, IsDOBCalculated: 'N', 
          Age: getDuration(date).years, 
          AgeMonth: getDuration(date).months, 
          AgeDay: getDuration(date).days, 
          DOB: date,
          DOBstr: date
      }))
    }
  
    const handleAniversary = (date) => {
      setRegisterData(pre => ({ 
          ...pre, 
          AnniversaryDate: date,
          AnniversaryDatestr: date
      }))
    }
  
    const renderLoginModal = () => {
      return (
        <>
        <div className="modal-header" style={{padding: '0.8em 1.3em 0.4em', borderRadius: '0.5em 0.5em 0 0'}}>
          <div className="modal-title h4">Please Login or Register to continue as a {modalMode}</div>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleFormClose}></button>
        </div>
        <div className='modal-body'>
          <div id='loginModal' className='d-flex flex-column flex-lg-row'>
            <div className='notes'>
              <ul>
                <li style={{'--clr': 'orangered'}}>
                  <p><i className='bx bx-check-circle'></i> Login or Register </p>
                </li>

                {(() => {
                  if (modalMode === 'PATIENT') {
                    return (
                      <>
                        <li style={{'--clr': '#00b900'}}>
                          <p><i className='bx bx-check-circle'></i> Book Doctor Appointment</p>
                        </li>
                        <li style={{'--clr': '#03A9F4'}}>
                          <p><i className='bx bx-check-circle'></i> Book Lab Tests</p>
                        </li>
                        <li style={{'--clr': '#d143e9'}}>
                          <p><i className='bx bx-check-circle'></i> View Medical Records</p>
                        </li>
                        <li style={{'--clr': '#905bed'}}>
                          <p><i className='bx bx-check-circle'></i> Add Family Members</p>
                        </li>
                      </>
                    )
                  } else if (modalMode === 'DOCTOR') {
                    return (
                      <>
                        <li style={{'--clr': '#00b900'}}>
                          <p><i className='bx bx-check-circle'></i> Book Doctor Appointment</p>
                        </li>
                        <li style={{'--clr': '#905bed'}}>
                          <p><i className='bx bx-check-circle'></i> Add Family Members</p>
                        </li>
                      </>
                    )
                  } else if (modalMode === 'PROVIDER') {
                    return (
                      <>
                        <Accordion className='acc-type-1'>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Read & Accept Partnership Terms</Accordion.Header>
                            <Accordion.Body>
                              Read our Partnership Terms "Partnership Terms, Conditions, and Privacy Policy" carefully and accept the check box given and submit the form.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Email KYC Document</Accordion.Header>
                            <Accordion.Body>
                              Email your Company PAN Card and GST Certificate on support@quick2tally.com, our team will verify your documents and approve your partner account.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>Avail Online Training</Accordion.Header>
                            <Accordion.Body>
                              After approval of your account, our executive will call you and provide online training of software and explain you all the features over call.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4" className='pb-0'>
                            <Accordion.Header>Start Selling Our Products</Accordion.Header>
                            <Accordion.Body>
                              After successfull training, start selling our software to your clients. Our team is always there to help you in case you face any issues while giving demo to your clients.
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </>
                    )
                  } else if (modalMode === 'COLLECTOR') {
                    return (
                      <>
                        <li style={{'--clr': '#00b900'}}>
                          <p><i className='bx bx-check-circle'></i> Book Doctor Appointment</p>
                        </li>
                        <li style={{'--clr': '#905bed'}}>
                          <p><i className='bx bx-check-circle'></i> Add Family Members</p>
                        </li>
                      </>
                    )
                  } else if (modalMode === 'POLYCLINIC') {
                    return (
                      <>
                        <li style={{'--clr': '#00b900'}}>
                          <p><i className='bx bx-check-circle'></i> Book Doctor Appointment</p>
                        </li>
                        <li style={{'--clr': '#905bed'}}>
                          <p><i className='bx bx-check-circle'></i> Add Family Members</p>
                        </li>
                      </>
                    )
                  } else {
                    return;
                  }
                })()}           

                {modalMode !== 'PROVIDER' ? <li style={{'--clr': '#FF9800'}}>
                  <p><i className='bx bx-check-circle'></i> And Much More.....</p>
                </li> : ''}
              </ul>
            </div>
            <div>
              <ul className="nav nav-tabs pb-2 border-0" role="tablist">
                <li className="nav-item w-50" role="presentation">
                  <button type="button" onClick={() => {setTabActive('login'); setLoginError({status: false, message: ''})}} id="tabFade-1" className={`nav-link w-100 ${isTabActive === 'login' ? 'active' : ''}`}>LOGIN</button>
                </li>
                <li className="nav-item w-50" role="presentation">
                  <button type="button" onClick={() => {setTabActive('register'); setLoginError({status: false, message: ''})}} id="tabFade-2" className={`nav-link w-100 ${isTabActive === 'register' ? 'active' : ''}`}>REGISTRATION</button>
                </li>
              </ul>
              <div className="tab-content pt-1">
                <div id="tabFade-pane-1" className={`tab-pane fade ${isTabActive === 'login' ? 'show active' : ''}`}>
                  <form onSubmit={handleLoginFormSubmit} className="pt-2">
                    <div className="row gx-2">
                      <div className="col-12">
                        <div className="form-group form-focus focused" id="lblMobile1">
                          <label className="focus-label"><b className='text-danger'>* </b>Mobile Number</label>
                          <input name="phone" onChange={(e) => handleNumberInputs(e, setLoginData)} value={loginData.phone} required className="form-control floating" tabIndex={1} id="loginPhone" maxLength={10} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group form-focus focused">
                          <label className="focus-label"><b className='text-danger'>* </b>Password</label>
                          <input onChange={handleLoginInput} value={loginData.password} required name="password" className="form-control floating" type="password" tabIndex={1} autoComplete='off' />
                        </div>
                        <p className="text-danger" style={{display: loginError.status ? 'block' : 'none'}}>{loginError.message}</p>
                        <Link to='' className="text-info cursor" onClick={() => {modalAction('LOGIN_MODAL', false, {mode: modalMode}); handleForgotPasswordModal(true);}}>Forgot Password ?</Link>
                        <button type="submit" className="d-block btn btn-primary ms-auto" style={{"minWidth": "9rem", "fontSize": "0.95rem"}}>Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div id="tabFade-pane-2" className={`tab-pane fade ${isTabActive === 'register' ? 'show active' : ''}`}>
                  <div className="row" id="divEnqDataContent">
                    <form className="bg-white rounded pt-2" onSubmit={handleRegisterFormSubmit} id="registrationForm">
                      <div className="col-md-12">
                        {/* <div className="row gx-2 mb-3 justify-content-between mx-0" id='userTypeTabs' style={{display: registerData.UserType !== 'PATIENT' ? 'flex' : 'none'}}>
                          {customTabsButtons(userTypes, registerData.UserType, handleUserType)}
                        </div> */}
                        <div className="row gx-2">
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group form-focus focused" id="lblMobile1">
                              <label className="focus-label"><b className='text-danger'>* </b>Mobile Number</label>
                              <input name="RegMob1" value={registerData.RegMob1} onChange={(e) => handleNumberInputs(e, setRegisterData)} required className="form-control floating" tabIndex={1} id="txtMobileNo1" maxLength={10} />
                            </div>
                            <p className="text-danger" style={{display: loginError.status ? 'block' : 'none'}}>{loginError.message}</p>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <span className={`btn btn-primary btnSave ms-auto fw-bold ${loginError.status ? 'disabled' : ''}`} onClick={checkExistingUser} style={{width: "8em", display: showNumberSubmitBtn ? 'block' : 'none'}} tabIndex={1}>NEXT</span>
                          </div>
                        </div>
  
                        <div className="row gx-2" style={{display: showOtpField ? 'flex' : 'none'}}>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group form-focus focused mb-0">
                              <label className="focus-label">ENTER YOUR OTP</label>
                              <input onChange={(e) => setEnteredOTP(e.target.value)} value={enteredOTP} className="form-control floating" type="text" id="txtPHeight" tabIndex={1} autoComplete='off' />
                              <span className='mark text-sm' onClick={resendOtp}  style={{width: "10rem"}} tabIndex={1}>Resend OTP</span>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12" style={{display: showOtpField ? 'block' : 'none'}}>
                            <span className='btn btn-primary btnSave ms-auto fw-bold d-block' onClick={compareOtp}  style={{width: "8em"}} tabIndex={1}>SUBMIT OTP</span>
                          </div>
                        </div>
  
                        <div style={{display: showOrgFields && modalMode === 'POLYCLINIC' ? 'block' : 'none'}}>
                          <h4 className="card-title">Organisation Information:-</h4>
                          <div className="row gx-2">
                            <div className="col-md-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Organisation Name</label>
                                <input name="compName" value={registerData.compName} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" autoComplete='off' />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-focus focused" id="lblMobile1">
                                <label className="focus-label">Organisation Mobile Number 1</label>
                                <input name="compPhone1" onChange={(e) => handleNumberInputs(e, setRegisterData)} value={registerData.compPhone1} className="form-control floating" tabIndex={1} maxLength={10} autoComplete='off' />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-focus focused" id="lblMobile1">
                                <label className="focus-label">Organisation Mobile Number 2</label>
                                <input name="compPhone2" onChange={(e) => handleNumberInputs(e, setRegisterData)} value={registerData.compPhone2} className="form-control floating" tabIndex={1} maxLength={10} autoComplete='off' />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-focus focused" id="lblMobile1">
                                <label className="focus-label">Organisation Email</label>
                                <input name="compMail" onChange={handleRegistrationInput} value={registerData.compMail} className="form-control floating" tabIndex={1} type='text' autoComplete='off'/>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Organisation Address</label>
                                <input name="compAddress" value={registerData.compAddress} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" autoComplete='off'/>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Organisation State</label>
                                <select name="compState" value={registerData.compState} onChange={handleRegistrationInput} tabIndex={1} className="form-control floating">
                                  <option value="">-Select-</option>
                                  {statesList.map(item => (<option key={item.CodeId} value={parseInt(item.CodeId)}>{item.Description}</option>))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Organisation Pin Code</label>
                                <input name="compPin" value={registerData.compPin} onChange={(e) => handleNumberInputs(e, setRegisterData)} className="form-control floating" tabIndex={1} type='text' maxLength={6}  autoComplete='off' />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <span className="btn btn-primary btnSave ms-auto fw-bold d-block" onClick={handleOrgSubmit} tabIndex="1" style={{width: "10rem"}}>NEXT</span>
                            </div>
                          </div>
                        </div>
  
                        <div style={{display: showPersonalFields ? 'block' : 'none'}}>
                          <h4 className="card-title">Personal Information:-</h4>
                          <div className="row gx-2">
                            <div className="col-4">
                              <div className="form-group form-focus focused">
                                <label className="focus-label"><b className='text-danger'>* </b>Salutation</label>
                                <select name="Salutation" value={registerData.Salutation} required onChange={(e) => {handleRegistrationInput(e); toggleGender(e);}} id="ddlSalutation" tabIndex={1} className="form-control">
                                  <option value="">-Select-</option>
                                  <option value="Dr">Dr</option>
                                  <option value="Mr">Mr</option>
                                  <option value="Ms">Ms</option>
                                  <option value="Mrs">Mrs</option>
                                  <option value="Miss">Miss</option>
                                  <option value="BabyOf">Baby Of</option>
                                  <option value="Master">Master</option>
                                  <option value="Baby">Baby</option>
                                  <option value="Md">Md.</option>
                                  <option value="Prof">Prof.</option>
                                  <option value="Rev">Rev.</option>
                                  <option value="Sk">Sk.</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="form-group form-focus focused">
                                <label className="focus-label"><b className='text-danger'>* </b>Name</label>
                                <input name="Name" value={registerData.Name} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" required/>
                              </div>
                            </div>
                          </div>
                          <div className="row gx-1 gx-md-2">
                            <div className="col-3">
                              <div className="form-group form-focus focused">
                                <label className="focus-label"><b className='text-danger'>* </b>Gender</label>
                                <select name="Gender" value={registerData.Gender} onChange={handleRegistrationInput} required tabIndex={1} className="form-control floating">
                                  <option value="">-Select-</option>
                                  {genderData.map(item => (<option key={item.CodeId} value={item.CodeId}>{item.Description}</option>))}
                                </select>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="form-group form-focus focused">
                                <label className="focus-label"><b className='text-danger'>* </b>DOB</label>
                                {/* <DatePicker 
                                  selected={new Date(registerData.DOB)}
                                  onChange={(date) => setRegisterData(pre => ({ ...pre, IsDOBCalculated: 'N', Age: getDuration(date?.toLocaleDateString('fr-CA')).years, AgeMonth: getDuration(date?.toLocaleDateString('fr-CA')).months, AgeDay: getDuration(date?.toLocaleDateString('fr-CA')).days, DOB: date ? date.toLocaleDateString('fr-CA') : new Date().toLocaleDateString('fr-CA')}))}
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  className="form-control"
                                  dateFormat="dd/MM/yyyy"
                                /> */}
                                <JQDatePicker id={'user_DOB'} isRequired={true} setState={setRegisterData} handler={calculateDuration} curValue={registerData.DOB} name={'DOB'} customClass={'form-control'} required />
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Years</label>
                                <input name="Age" value={registerData.Age} onChange={(e) => handleNumberInputsWithDate(e, setRegisterData)} className="form-control floating" tabIndex={1} type='text' maxLength={2} id="txtPtAge" />
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Months</label>
                                <input name="AgeMonth" value={registerData.AgeMonth} onChange={(e) => handleNumberInputsWithDate(e, setRegisterData)} className="form-control floating" tabIndex={1} type='text' maxLength={2} id="txtPtAgeMonth"/>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Days</label>
                                <input name="AgeDay" value={registerData.AgeDay} onChange={(e) => handleNumberInputsWithDate(e, setRegisterData)} className="form-control floating" tabIndex={1} type='text' maxLength={2} id="txtPtAgeDay"/>
                              </div>
                            </div>
                          </div>
  
                          <div className="row gx-2" style={{display: specializations.isFieldOpen ? 'flex' : 'none'}}>
                            <div className="col-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Qualification</label>
                                <input name="Qualification" value={registerData.Qualification} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" id="txtQualification" autoComplete='off' />
                              </div>
                            </div>
  
                            <div className="col-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Specialization</label>
                                <select name="SpecialistId" value={registerData.SpecialistId} onChange={handleRegistrationInput} id="SpecialistId" tabIndex={1} className="form-control floating" autoComplete='off'>
                                  <option value="">-Select-</option>
                                  {specializations.data.map(item => (<option key={item.SubCode} value={item.SubCode}>{item.Description}</option>))}
                                </select>
                              </div>
                            </div>

                            {/* <div className="col-4">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Reg. No</label>
                                <input name="RegNo" value={registerData.RegNo} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" id="txtRegNo" autoComplete='off'/>
                              </div>
                            </div> */}

                          </div>

                          {(modalMode === 'DOCTOR' || modalMode === 'POLYCLINIC' || modalMode === 'PROVIDER') && <div className="row gx-2">
                            <div className="col-12">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Reg. No</label>
                                <input name="RegNo" value={registerData.RegNo} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" id="txtRegNo" autoComplete='off'/>
                              </div>
                            </div>
                          </div>}
  
                          {/* {modalMode === 'PATIENT' && <div className="row gx-2">                    
                            <div className="col-12">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Aadhaar Number</label>
                                <input name="Aadhaar" value={registerData.Aadhaar} onChange={(e) => handleNumberInputs(e, setRegisterData)} className="form-control floating" maxLength={12} tabIndex={1} type="text" autoComplete='off' />
                              </div>
                            </div>
                          </div>} */}
  
                          {/* {modalMode !== 'PATIENT' &&  <div className="row gx-2">                    
                            <div className="col-12">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Aniversary Date</label>
                                <JQDatePicker id={'user_aniversary'} setState={setRegisterData} handler={handleAniversary} curValue={registerData.AnniversaryDate} name={'AnniversaryDate'} customClass={'form-control'} />
                              </div>
                            </div>
                          </div>} */}
  
                          <div className="row gx-2">
                            {/* <div className="col-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Apartment / Flat no. (optional)</label>
                                <input name="Address2" value={registerData.Address2} onChange={handleRegistrationInput} tabIndex={1} className="form-control floating" />
                              </div>
                            </div>                       */}
                            <div className="col-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">Address</label>
                                <input name="Address" value={registerData.Address} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type="text" />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label">City</label>
                                <input name="City" value={registerData.City} onChange={handleRegistrationInput} className="form-control floating" tabIndex={1} type='text'/>
                              </div>
                            </div>
                          </div>
  
                          <div className="row gx-2">
                            <div className="col-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label"><b className='text-danger'>* </b>State</label>
                                <select name="State" value={registerData.State} onChange={(e) => handleStateDropdown(e.target.value)} required tabIndex={1} className="form-control floating">
                                  <option value="">-Select-</option>
                                  {statesList.map(item => (<option key={item.CodeId} value={parseInt(item.CodeId)}>{item.Description}</option>))}
                                </select>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label"><b className='text-danger'>* </b>Pin Code</label>
                                <input name="Pin" value={registerData.Pin} onChange={(e) => handleNumberInputs(e, setRegisterData)} required className="form-control floating" tabIndex={1} type='text' maxLength={6} />
                              </div>
                            </div>
                          </div>
  
                          <div className="row gx-2">
                            <div className="col-md-6">
                              <div className="form-group form-focus focused">
                                <label className="focus-label"><b className='text-danger'>* </b>Password</label>
                                <input name="UserPassword" value={registerData.UserPassword} onChange={handleRegistrationInput} className="form-control floating" type="password" tabIndex={1} required autoComplete='off'/>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <p className='text-danger text-sm ps-1'>Enter a strong password to complete Registration and keep it for future logins.</p>
                            </div>
                          </div>                                          
                          <button type="submit" className="btn btn-primary d-block btnSave mx-auto fw-bold" style={{width: "10rem"}} tabIndex={1}>SUBMIT</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
        
      )
    }
                                                                                        // pass child={ForgotPassword({param1: true})} if have parameters to pass as props. Only function Componets can be passed in this manner. 
                                                                                        // Redux state connected function Components become objects hence they can't be passed like this.
    return (                                                                            // Child passing this way solves the loosing focus issue in passed child compoents which is caused by anwanted reRenders.  
      <>                                                                                   
        <ModalComponent isActive={alertVisible} heading="Thank You for Booking." child={showReference()} handleClose={setAlertVisible}/>
        <ModalComponent isActive={forgotPasswordModal.isOpen} heading="Enter your Registerd mobile number." child={ForgotPassword()} handleClose={handleForgotPasswordModal}/>
        {/* <ModalComponent isActive={modalOpen} className='login-modal' heading="Please Login or Register to continue" child={renderLoginModal()} handleClose={handleFormClose}/> */}
        {/* {modalOpen && <MyModal className='login-modal' heading="Please Login or Register to continue" child={renderLoginModal()} handleClose={handleFormClose}/>} */}
        {modalOpen && <MyModal name='local-handler' handleClose={() => {}} customClass='login-modal' width='55em' child={renderLoginModal()} closeIcon={false}/>}
      </>
    )
  }
  
  // ***** LoginModal component is not following mount and unmount pattern which prevents auto reset of the component. this needs to be solved.
  
  const mapStateToPropsTwo = (state) => {
    return { compCode: state.compCode, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, isLoading: state.isLoading, bookingInfo: state.bookingInfo };
  }
  
  export const ConnectedLoginModal = connect(mapStateToPropsTwo, {loginStatusAction, userInfoAction, loaderAction})(LoginModal);