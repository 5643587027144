import { connect } from "react-redux";
import { BSN_ID, TAKE_HOME_ID, XYZ_ID, bhsId, ePharmaId } from "../../constants";

const CssRoute = ({ compCode }) => {
    
    if (compCode === BSN_ID || compCode === bhsId) {
        import('../CSS/bsn/styles.css');
        import('../CSS/bsn/responsive.css');
        if (compCode === bhsId) {
            import('../CSS/bhs.css');
            import('../CSS/bsn/modifier.css');
        }
    } else if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
        import('../CSS/ePharma/ePharma.css');
        import('../CSS/ePharma/ePharma-responsive.css');
        if (compCode === ePharmaId) {
            import('../CSS/ePharma/ePharma-modify.css');
        } else if (compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
            import('../CSS/takeHome/takeHome.css');
        }
    } else {
        import('../CSS/default/default-modify.css');
    }
}

const mapStateToCssRoute = (state) => {
    return { compCode: state.compCode };
}
  
export default connect(mapStateToCssRoute, {})(CssRoute);


