import { Link } from "react-router-dom";

const Blog = () => {
    return (
        <div className="bsn-global">
           <section className="breadcrumb-area" style={{backgroundImage: 'url(/assets/img/aboutUs/aboutus-background.jpg)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumbs">
                                <h1>Single Blog</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="left pull-left">
                                    <ul>
                                        <li><a href="index.html">Home</a></li>
                                        <li><span className="material-symbols-outlined">navigate_next</span></li>
                                        <li className="active">Single Blog</li>
                                    </ul>
                                </div>
                                <div className="right pull-right">
                                    <a href="#"><span className="material-symbols-outlined">share</span> Share</a> 
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="blog-area" className="blog-single-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div className="blog-post">
                                <div className="single-blog-item">
                                    <div className="img-holder">
                                        <img src="/assets/img/blogs/blog/blog-single.jpg" alt="Awesome Image"/>
                                    </div>
                                    <div className="text-holder">
                                        <h3 className="blog-title">How to handle your kids’ from mystery ailments.</h3>
                                        <div className="text">
                                            <p className="top">The great explorer of the truth, the master-builder of human happiness worksone rejects, dislikes, avoids pleasure itself, because it is pleasure, but because those who do  know how to pursue pleasure rationally encounter consequences that are extremely seds painful the master-builder of human happiness rejects, dislikes, or avoids pleasure itself, because it is pursue pleasure.</p>
                                            <p>Who loves or pursues or desires to obtain pain, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage.</p>
                                        </div>
                                        <div className="middle-content-box">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="img-holder">
                                                        <img src="/assets/img/blogs/blog/project-manager.jpg" alt="Awesome Image"/>
                                                    </div>
                                                    <div className="text-holder">
                                                        <p>They have got my project time with competition  with highly skilled, well-organized denouncing of pleasures experienced team of professional.</p>
                                                        <h3>Dohn Gaskel</h3>
                                                        <h5>Head of Department</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom-content-box">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="text-holder">
                                                        <p>Know how to pursue pleasure rationally encounter consequences that are extremely painful seds or again is there anyone who loves or pursues or desires to obtain pain of itself, because seds our it pain, but because occasionally circumstances occur which toil and pain can  undertakes laborious physical exercise.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="meta-info">
                                            <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i>February 14, 2017</a></li>
                                            <li><a href="#"><i className="fa fa-comments-o" aria-hidden="true"></i>18 Comments</a></li>
                                            <li><a href="#"><i className="fa fa-folder-open" aria-hidden="true"></i>Transplantation</a></li>
                                            <li><a href="#"><i className="fa fa-user" aria-hidden="true"></i>Mark Nicholes</a></li>
                                        </ul>
                                    </div>    
                                </div>
                                <div className="tag-social-share-box">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="social-share clearfix">
                                                <h5>Share<i className="fa fa-share-alt" aria-hidden="true"></i></h5>
                                                <ul className="social-share-links">
                                                    <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="author-box">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/blog/author.jpg" alt="Awesome Image"/>
                                            </div>
                                            <div className="text-holder">
                                                <h3>Mark Richardson</h3>
                                                <p>We will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.</p>
                                                <ul className="social-link">
                                                    <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-youtube" aria-hidden="true"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="comment-box">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="sec-title pdb-30">
                                                <h3>Read Comments</h3>
                                                <span className="border"></span>
                                            </div>
                                            <div className="single-comment-box">
                                                <div className="img-holder">
                                                    <img src="/assets/img/blogs/blog/comment-1.jpg" alt="Awesome Image"/>
                                                </div>
                                                <div className="text-holder">
                                                    <div className="top">
                                                        <div className="date pull-left">
                                                            <h5>Steven Rich – Sep 17, 2016:</h5>
                                                        </div>
                                                        <div className="review-box pull-right">
                                                            <ul>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="text">
                                                        <p>How all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-comment-box">
                                                <div className="img-holder">
                                                    <img src="/assets/img/blogs/blog/comment-2.jpg" alt="Awesome Image"/>
                                                </div>
                                                <div className="text-holder">
                                                    <div className="top">
                                                        <div className="date pull-left">
                                                            <h5>William Cobus – Aug 21, 2016:</h5>
                                                        </div>
                                                        <div className="review-box pull-right">
                                                            <ul>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star"></i></li>
                                                                <li><i className="fa fa-star-half-o"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="text">
                                                        <p>there anyone who loves or pursues or desires to obtain pain itself, because it is pain, because occasionally circumstances occur some great pleasure.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-comment-box">
                                    <div className="sec-title pdb-30">
                                        <h3>Add Your Comments</h3>
                                        <span className="border"></span>
                                    </div>
                                    <div className="add-rating-box">
                                        <h4>Your Rating</h4>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i className="fa fa-star"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>   
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <form id="add-comment-form" name="comment-form" action="#" method="post" novalidate="novalidate">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="field-label">First Name*</div>
                                                        <input type="text" name="fname" placeholder="" required="" aria-required="true"/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="field-label">Last Name*</div>
                                                        <input type="text" name="lname" placeholder="" required="" aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="field-label">Email*</div>
                                                        <input type="email" name="email" placeholder="" required="" aria-required="true"/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="field-label">Your Comments</div>
                                                        <textarea name="comment"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <button className="thm-btn bgclr-1" type="submit">Submit Now</button>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-7 col-xs-12">
                            <div className="sidebar-wrapper">
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <form className="search-form" action="#">
                                        <input placeholder="Search..." type="text"/>
                                        <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </form>
                                </div>
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0">
                                    <div className="sec-title">
                                        <h3>Categories</h3>
                                    </div>
                                    <ul className="categories clearfix">
                                        <li><a href="#">Procedures<span>(6)</span></a></li>
                                        <li><a href="#">Transplantation<span>(2)</span></a></li>
                                        <li><a href="#">Management<span>(5)</span></a></li>
                                        <li><a href="#">Healthcare Tips<span>(10)</span></a></li>
                                        <li><a href="#">Uncategorized<span>(4)</span></a></li>
                                    </ul>
                                </div>
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <div className="sec-title">
                                        <h3>Popular Posts</h3>
                                    </div>
                                    <ul className="popular-post">
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/popular-post-1.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="title-holder">
                                                <a href="#"><h5 className="post-title">How to handle your kids’<br/> from mystery ailments</h5></a>
                                                <h6 className="post-date">June 21, 2017</h6>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/popular-post-2.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="title-holder">
                                                <a href="#"><h5 className="post-title">Lung cancer survival rate in <br/>England improves</h5></a>
                                                <h6 className="post-date">February 14, 2017</h6>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/popular-post-3.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="title-holder">
                                                <a href="#"><h5 className="post-title">Negative statin stories add <br/>to heart health risk</h5></a>
                                                <h6 className="post-date">January 17, 2017</h6>
                                            </div>
                                        </li>
                                    
                                    
                                    </ul>
                                </div>
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <div className="sec-title">
                                        <h3 className="pull-left">Instagram</h3>
                                        <a className="pull-right follow-us" href="#">Follow Us</a>
                                    </div>
                                    <ul className="instagram">
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-1.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-2.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-3.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-4.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> 
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-5.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> 
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-6.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> 
                                    </ul>
                                </div>
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <div className="sec-title">
                                        <h3>Facebook Feed</h3>
                                    </div>
                                    <div className="fb-feed">
                                        <img src="/assets/img/blogs/sidebar/facebook-feed.jpg" alt=""/>
                                    </div>     
                                </div> 
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <div className="sec-title">
                                        <h3>Tag Cloud</h3>
                                    </div>
                                    <ul className="popular-tag">
                                        <li><a href="#">Ideas</a></li>
                                        <li><a href="#">Doctor</a></li>
                                        <li><a href="#">Experts</a></li>
                                        <li><a href="#">Tips</a></li>
                                        <li><a href="#">Health</a></li>
                                        <li><a href="#">Services</a></li>
                                        <li><a href="#">Growth</a></li>
                                    </ul>      
                                </div> 
                            </div>    
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Blog;