import { connect } from "react-redux";
import { modalAction, userInfoAction } from "../../../../actions";
import { Link, useHistory } from "react-router-dom";
// import { logOut } from "../utilities";

const UserInfoModal = ({ modalAction, userInfo }) => {

    const history = useHistory();

    // const handleLogout = () => {
    //     loginStatusAction(false);
    //     userInfoAction('DUMP_USER_INFO'); 
    //     modalAction('USER_INFO_MODAL', false);
    //     dumpCartAction();
    //     dumpWishlistAction();
  
    // }

    return (
        <div className="user-info-modal">
            {/* <div>
                <button className="logout-btn" onClick={() => modalAction('USER_INFO_MODAL', false)}><i className='bx bx-x-circle'></i></button>
                <div className="img-circle">
                    <img src="/assets/img/ePharma/user_unknown.png" style={{maxHeight: '10rem'}} alt="user"/>
                </div>
                <div className="user-content d-flex align-items-center flex-column text-center">
                    <h4 className="user-name">{userInfo.Name}</h4>
                    <h6 className="user-number">{userInfo.RegMob1}</h6>
                    <p className="user-email">{userInfo.Email}</p>
                    <p className="user-address">{userInfo.Address}</p>
                </div>
                <div className="cart-buttons mt-20">
                    <button onClick={() => {history.push('/cartPage'); modalAction('USER_INFO_MODAL', false)}} className="button">Visit Cart</button>
                    <button className="button" onClick={() => {history.push('/myOrders'); modalAction('USER_INFO_MODAL', false)}}>My Orders</button>
                </div>
                <div className="links-box">
                    <Link className="link-box-item" to='/logout'><i className='bx bx-log-out'></i> Logout</Link>
                    <span className="link-box-item" onClick={() => {modalAction('EDIT_USER_MODAL', true)}}><i className='bx bx-edit'></i> Edit Profile</span>
                </div>
            </div> */}
            <div className="d-flex gap-4" style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'}}>
                <button className="logout-btn" onClick={() => modalAction('USER_INFO_MODAL', false)}><i className='bx bx-x-circle'></i></button>
                <div className="d-flex gap-4 w-100" style={{borderBottom: '1px solid #d8d8d8', paddingBottom: '1.1em'}}>
                    <img src="/assets/img/ePharma/user_unknown.png" style={{maxHeight: '4.3em'}} alt="user"/>
                    <div>
                        <h2 className="mb-2" style={{fontSize: '1.2em', fontWeight: 600}}>{userInfo.Name}</h2>
                        <p className="mb-0">{userInfo.GenderDesc}</p>
                    </div>
                </div>
                <div className="user-content w-100" style={{borderBottom: '1px solid #d8d8d8', paddingBottom: '1.1em'}}>
                    <div className="row-list">
                        <div className="row-item align-items-center">
                            <h6>Phone: </h6>
                            <p className="user-number" style={{color: '#5260ff', fontWeight: 600, padding: '0.4rem 1.2rem 0.2rem'}}>{userInfo.RegMob1}</p>
                        </div>
                        <div className="row-item">
                            <h6>Address: </h6>
                            <p>{userInfo.Address2}, {userInfo.Address}, {userInfo.City}</p>
                        </div>
                        <div className="row-item">
                            <h6>Pin Code: </h6>
                            <p>{userInfo.Pin}</p>
                        </div>
                        <div className="row-item">
                            <h6>State: </h6>
                            <p>{userInfo.StateName}</p>
                        </div>
                        <div className="row-item">
                            <h6>E-mail: </h6>
                            <p>{userInfo.Email}</p>
                        </div>
                        <div className="row-item">
                            <h6>DOB:  </h6>
                            <p style={{letterSpacing: 1}}>{new Date(userInfo.DOB).toLocaleDateString('en-TT')}</p>
                        </div>
                    </div>
                </div>
                <div className="cart-buttons mt-2" style={{fontSize: '0.9em'}}>
                    <button onClick={() => {history.push('/cartPage'); modalAction('USER_INFO_MODAL', false)}} className="button">Visit Cart</button>
                    <button className="button" onClick={() => {history.push('/myOrders'); modalAction('USER_INFO_MODAL', false)}}>My Orders</button>
                </div>
                <div className="links-box mt-0">
                    <Link className="link-box-item" to='/logout'><i className='bx bx-log-out'></i> Logout</Link>
                    <span className="link-box-item" onClick={() => {modalAction('EDIT_USER_MODAL', true)}}><i className='bx bx-edit'></i> Edit Profile</span>
                </div>
            </div>
        </div>
    )
}

const mapStateToLoginModal = (state) => {
    return { isLoggedIn: state.isLoggedIn, compCode: state.compCode, userInfo: state.userInfo };
}

export default connect(mapStateToLoginModal, {modalAction, userInfoAction})(UserInfoModal);