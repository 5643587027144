


const Careers = () => {
    return (
        <div className="bsn-global">
            <section className="breadcrumb-area" style={{backgroundImage: 'url(/assets/img/aboutUs/aboutus-background.jpg)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumbs">
                                <h1>Careers</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="left pull-left">
                                    <ul>
                                        <li><a href="index.html">Home</a></li>
                                        <li><span className="material-symbols-outlined">navigate_next</span></li>
                                        <li className="active">Careers</li>
                                    </ul>
                                </div>
                                <div className="right pull-right">
                                    <a href="#"><span className="material-symbols-outlined">share</span> Share</a> 
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-form-area">
                <div className="container">
                    <div className="sec-title">
                        <h1>Available Vacancies</h1>
                        <span className="border"></span>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="quick-contact career-sectoin mb-3 mb-lg-0">
                                <ul className="career">
                                    <li>
                                        <div className="careerLisRow d-flex justify-content-md-between justify-content-center">
                                            <div>
                                                <div className="careerInfo">
                                                    <h3>Available Job Name</h3>
                                                    <p>We are looking for a well educated and hardworking male/female individual to join our healthcare department. Please fill out the given form to apply for the opportunity.</p>
                                                    <div className="d-flex">
                                                        <span> <p>Onsite/Remote</p></span>
                                                        <span><p> Full-time</p></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mx-auto me-sm-0 mt-3 mt-sm-0">
                                                <a className="readmore my-btn-1 btn-solid" href="gynecology.html">Apply Now</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="careerLisRow d-flex justify-content-md-between justify-content-center">
                                            <div>
                                                <div className="careerInfo">
                                                    <h3>Available Job Name</h3>
                                                    <p>We are looking for a well educated and hardworking male/female individual to join our healthcare department. Please fill out the given form to apply for the opportunity.</p>
                                                    <div className="d-flex">
                                                        <span> <p>Onsite/Remote</p></span>
                                                        <span><p> Full-time</p></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mx-auto me-sm-0 mt-3 mt-sm-0">
                                                <a className="readmore my-btn-1 btn-solid" href="gynecology.html">Apply Now</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="careerLisRow d-flex justify-content-md-between justify-content-center">
                                            <div>
                                                <div className="careerInfo">
                                                    <h3>Available Job Name</h3>
                                                    <p>We are looking for a well educated and hardworking male/female individual to join our healthcare department. Please fill out the given form to apply for the opportunity.</p>
                                                    <div className="d-flex">
                                                        <span> <p>Onsite/Remote</p></span>
                                                        <span><p> Full-time</p></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mx-auto me-sm-0 mt-3 mt-sm-0">
                                                <a className="readmore my-btn-1 btn-solid" href="gynecology.html">Apply Now</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="careerLisRow d-flex justify-content-md-between justify-content-center">
                                            <div>
                                                <div className="careerInfo">
                                                    <h3>Available Job Name</h3>
                                                    <p>We are looking for a well educated and hardworking male/female individual to join our healthcare department. Please fill out the given form to apply for the opportunity.</p>
                                                    <div className="d-flex">
                                                        <span> <p>Onsite/Remote</p></span>
                                                        <span><p> Full-time</p></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mx-auto me-sm-0 mt-3 mt-sm-0">
                                                <a className="readmore my-btn-1 btn-solid" href="gynecology.html">Apply Now</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="careerLisRow d-flex justify-content-md-between justify-content-center">
                                            <div>
                                                <div className="careerInfo">
                                                    <h3>Available Job Name</h3>
                                                    <p>We are looking for a well educated and hardworking male/female individual to join our healthcare department. Please fill out the given form to apply for the opportunity.</p>
                                                    <div className="d-flex">
                                                        <span> <p>Onsite/Remote</p></span>
                                                        <span><p> Full-time</p></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mx-auto me-sm-0 mt-3 mt-sm-0">
                                                <a className="readmore my-btn-1 btn-solid" href="gynecology.html">Apply Now</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                {/* <ul className="social-links">
                                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                    <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                                    <li><a href="#"><i className="fa fa-youtube"></i></a></li>
                                </ul> */}
                            </div>    
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="contact-form">
                                <form id="contact-form" name="contact_form" className="default-form" action="inc/sendmail.php" method="post" noValidate="novalidate">
                                    <h2>Fill Out The Form</h2>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input type="text" name="form_name" value="" placeholder="Your Name*" required="" aria-required="true" fdprocessedid="s3dufj"/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="email" name="form_email" value="" placeholder="Your Mail*" required="" aria-required="true" fdprocessedid="fjrja"/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="text" name="form_phone" value="" placeholder="Phone" fdprocessedid="dxii2d"/>
                                        </div>
                                        <div className="col-md-12">
                                            <input type="text" name="form_subject" value="" placeholder="Subject" fdprocessedid="gmzny"/>
                                        </div>
                                        <div className="col-md-12">
                                            <textarea name="form_message" placeholder="Your Message.." required="" aria-required="true"></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value=""/>
                                            <button className="thm-btn bgclr-1" type="submit" data-loading-text="Please wait..." fdprocessedid="l1iazs">Submit</button>
                                        </div>
                                    </div>
                                </form>  
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Careers;