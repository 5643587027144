import { modalAction } from "../../../../actions";
import { navigateToDashboard } from "../utilities";
import { connect } from "react-redux";


const Dashboard = ({ userInfo, isLoggedIn, modalAction }) => {

    
    if (!isLoggedIn) {
       modalAction('LOGIN_MODAL', true, {mode: 'PATIENT'});
       return;
    };
    return navigateToDashboard(userInfo.UserType);

}

const mapStateToDashboard = (state) => {
    return { userInfo: state.userInfo, isLoggedIn: state.isLoggedIn }
}

export default connect(mapStateToDashboard, {modalAction})(Dashboard);