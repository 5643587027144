import { useEffect } from "react";
import { connect } from "react-redux";
import { decrypt, encrypt } from "./utilities";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { loginStatusAction, userInfoAction, compCodeAction, loaderAction, modalAction } from "../../../actions";


const Authentication = ({ compCode, userInfo, bookingInfo, modalAction, loginStatusAction, userInfoAction, compCodeAction, loaderAction }) => {

    // const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true, decode: false });
    const history = useHistory();
    
    useEffect(() => {
      // if (queryString.CID && queryString?.CID !== compCode) {                     // immediate calling api's that uses CID parameter must be filtered before calling
      //   AutoLogin(queryString.CID);
      // } else {
      //   AutoLogin(compCode);
      // }
      autoLogin(compCode);
    }, [compCode])
    // ----------------------- Auto Login Starts --------------------------------------------------------------------------------------------------------------------------

  function autoLogin(companyCode) {
    const savedLoginData = decrypt(localStorage.getItem('userLoginData'));
    if (companyCode !== '') {
      if (savedLoginData) compareCompCodes(companyCode, savedLoginData); 
    } else {
      console.log('No root compcode found');
      saveAndLoginUser(savedLoginData);
    }
  }
  function compareCompCodes(currCompCode, savedLoginData) {               // defining it outside the useEffect without usecallback makes it not trigger on compcode changes. leaving user logged out. 
    if (currCompCode === savedLoginData.compCode) {
      console.log(currCompCode, savedLoginData.compCode);
      saveAndLoginUser(savedLoginData);
      return;
    }
    console.log('Company code Mismatch.');
    loginStatusAction(false);
  }

  const saveAndLoginUser = (savedData) => {
    if (savedData && savedData.phone) {
      console.log('Logging you in! Please wait.');        
      makeLoginRequest({ phone: savedData.phone, password: savedData.password, companyCode: savedData.compCode });
    } 
  }

  const makeLoginRequest = async (params) => {
    loaderAction(true);
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth?UN=${params.phone}&UP=${params.password}&CID=${params.companyCode}`);
    loaderAction(false);
    if (res.data.Remarks === 'INVALID') {
      console.log('The username or password is incorrect.');
    } else {
      let userLoginData = {
                            Name: res.data.UserFullName,
                            RegMob1: params.phone,
                            UserId: res.data.UserId,
                            UserType: res.data.UserType,
                            PartyCode: res.data.PartyCode,
                            EncCompanyId: params.companyCode,
                            Age: res.data.Age,
                            AgeDay: res.data.AgeDay,
                            AgeMonth: res.data.AgeMonth,
                            Gender: res.data.Gender,
                            GenderDesc: res.data.GenderDesc,
                            MPartyCode: res.data.MPartyCode,
                            Address: res.data.Address,
                            Qualification: res.data.Qualification,
                            SpecialistDesc: res.data.SpecialistDesc,
                            RegNo: res.data.RegNo ? res.data.RegNo : '',
                            State: res.data.State,                          
                            City: res.data.City,
                            Pin: res.data.Pin,
                            Address2: res.data.Address2,
                            UHID: res.data.UHID,
                            MemberId: res.data.MemberId,
                            PartyId: res.data.PartyId,
                            Salutation: res.data.Salutation,
                            Email: res.data.Email,
                            Country: res.data.Country,
 
                            DOB: res.data.DOB,
                            AnniversaryDate: res.data.AnniversaryDate,
                            Aadhaar: res.data.Aadhaar,
                            IsDOBCalculated: res.data.IsDOBCalculated,
 
                            compName: res.data.compName ? res.data.compName: '',
                            compAddress: res.data.compAddress ? res.data.compAddress: '',
                            compState: res.data.compState ? res.data.compState: '',
                            compPin: res.data.compPin ? res.data.compPin: '',
                            compPhone1: res.data.compPhone1 ? res.data.compPhone1: '',
                            compPhone2: res.data.compPhone2 ? res.data.compPhone2: '',
                            compMail: res.data.compMail ? res.data.compMail: '',
                            UserPassword: res.data.UserPassword
                         };
      //  localStorage.setItem("userLoginData", encrypt({ phone: params.phone, password: res.data.UserPassword, compCode: params.companyCode }));       // need to overwrite the existing login data.
       userInfoAction(userLoginData);
 
       const newbookingData = { 
         ...bookingInfo,
         Salutation: res.data.Salutation,
         Name: res.data.Name,
         EncCompanyId: userInfo.selectedCompany.EncCompanyId,
         PartyCode: userInfo.selectedCompany.CompUserPartyCode,
         MPartyCode: userInfo.selectedCompany.CompUserMPartyCode,
         RegMob1: res.data.RegMob1,
         Gender: res.data.Gender ,
         GenderDesc: res.data.GenderDesc ,
         Address: res.data.Address ,
         Age: res.data.Age,
         AgeMonth: res.data.AgeMonth,
         AgeDay: res.data.AgeDay,
         State: res.data.State ,
         City: res.data.City,
         Pin: res.data.Pin,
         Address2: res.data.Address2,
         AnniversaryDate: res.data.AnniversaryDate,
         Aadhaar: res.data.Aadhaar ,
         UserId: res.data.UserId,
         UHID: res.data.UHID,
         MemberId: res.data.MemberId,
         Country: res.data.Country,
       }
       handleRedirect(res.data.UserType, newbookingData);
 
       modalAction('LOGIN_MODAL', false, {mode: 'PATIENT'});
       loginStatusAction(true);
     }
  }

  const handleRedirect = (userType, bookingData) => {
    switch (userType) {
      case 'PATIENT':
        if (!bookingData.TimeSlotId) {
          // history.push('/');
        } else {
          // makeBookingRequest(bookingData);        
          // setTimeout(() => { history.push('/dashboard') }, 1000);
        }
        break;
      default:
        // history.push('/dashboard');
        break;
    }                                                    
  }

// ----------------------- Auto Login Ends --------------------------------------------------------------------------------------------------------------------------
    return
}


const mapStateToAuth = (state) => {
    return { compCode: state.compCode, compInfo: state.compInfo, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, cart: state.cart, siteData: state.siteData, bookingInfo: state.bookingInfo };
  }
  
export default connect(mapStateToAuth, {loginStatusAction, userInfoAction, compCodeAction, loaderAction, modalAction})(Authentication);
