import { Link } from "react-router-dom";

const Blogs = () => {
    return (
        <div className="bsn-global">
           <section className="breadcrumb-area" style={{backgroundImage: 'url(/assets/img/aboutUs/aboutus-background.jpg)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumbs">
                                <h1>Health Blogs</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="left pull-left">
                                    <ul>
                                        <li><a href="index.html">Home</a></li>
                                        <li><span className="material-symbols-outlined">navigate_next</span></li>
                                        <li className="active">Health Blogs</li>
                                    </ul>
                                </div>
                                <div className="right pull-right">
                                    <a href="#"><span className="material-symbols-outlined">share</span> Share</a> 
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="blog-area" className="blog-default-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div className="blog-post">
                                <div className="row">
                                    <div className="col-md-6">   
                                        <div className="single-blog-item wow fadeInUp animated" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0">
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/blog-default-1.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="blog-single.html"><span className="material-symbols-outlined">add</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <Link to={'/blogs/1234'}>
                                                    <h3 className="blog-title">How to handle your kids’ from mystery ailments</h3>
                                                </Link>
                                                <div className="text">
                                                    <p>The great explorer of the truth, master builder of human happiness one rejects, dislikes, or avoids pleasure itself because it is pleasure.</p>
                                                </div>
                                                <ul className="meta-info">
                                                    <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i>February 14, 2017</a></li>
                                                    <li><a href="#"><i className="fa fa-comments-o" aria-hidden="true"></i>21 Comments</a></li>
                                                </ul>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="col-md-6">   
                                        <div className="single-blog-item wow fadeInUp animated" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/blog-default-2.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="blog-single.html"><span className="material-symbols-outlined">add</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <Link to={'/blogs/1234'}>
                                                    <h3 className="blog-title">Negative statin stories add to heart health risk</h3>
                                                </Link>
                                                <div className="text">
                                                    <p>There anyone who loves or pursues or desires to obtain pains of itself, because it is pain because occasionally circumstances occur.</p>
                                                </div>
                                                <ul className="meta-info">
                                                    <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i>January 21, 2017</a></li>
                                                    <li><a href="#"><i className="fa fa-comments-o" aria-hidden="true"></i>18 Comments</a></li>
                                                </ul>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">   
                                        <div className="single-blog-item wow fadeInUp animated" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/blog-default-3.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="blog-single.html"><span className="material-symbols-outlined">add</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <Link to={'/blogs/1234'}>
                                                    <h3 className="blog-title">Lung cancer survival rate in England improves</h3>
                                                </Link>
                                                <div className="text">
                                                    <p>Which toil and pain can procure him some great pleasure. To take a trivial example, which of us  laborious physical exercise.</p>
                                                </div>
                                                <ul className="meta-info">
                                                    <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i>January 15, 2017</a></li>
                                                    <li><a href="#"><i className="fa fa-comments-o" aria-hidden="true"></i>09 Comments</a></li>
                                                </ul>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="col-md-6">   
                                        <div className="single-blog-item wow fadeInUp animated" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/blog-default-4.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="blog-single.html"><span className="material-symbols-outlined">add</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <Link to={'/blogs/1234'}>
                                                    <h3 className="blog-title">How to handle your kids’ from mystery ailments</h3>
                                                </Link>
                                                <div className="text">
                                                    <p>The great explorer of the truth, master builder of human happiness one rejects, dislikes, or avoids pleasure itself because it is pleasure.</p>
                                                </div>
                                                <ul className="meta-info">
                                                    <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i>February 14, 2017</a></li>
                                                    <li><a href="#"><i className="fa fa-comments-o" aria-hidden="true"></i>21 Comments</a></li>
                                                </ul>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 wow fadeInUp animated" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" > 
                                        <div className="single-blog-item">
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/blog-default-5.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="blog-single.html"><span className="material-symbols-outlined">add</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <Link to={'/blogs/1234'}>
                                                    <h3 className="blog-title">Negative statin stories add to heart health risk.</h3>
                                                </Link>
                                                <div className="text">
                                                    <p>There anyone who loves or pursues or desires to obtain pains of itself, because it is pain because occasionally circumstances occur.</p>
                                                </div>
                                                <ul className="meta-info">
                                                    <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i>January 21, 2017</a></li>
                                                    <li><a href="#"><i className="fa fa-comments-o" aria-hidden="true"></i>18 Comments</a></li>
                                                </ul>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="col-md-6 wow fadeInUp animated" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" > 
                                        <div className="single-blog-item">
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/blog-default-6.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="blog-single.html"><span className="material-symbols-outlined">add</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <Link to={'/blogs/1234'}>
                                                    <h3 className="blog-title">Lung cancer survival rate in England improves</h3>
                                                </Link>
                                                <div className="text">
                                                    <p>Which toil and pain can procure him some great pleasure. To take a trivial example, which of us  laborious physical exercise.</p>
                                                </div>
                                                <ul className="meta-info">
                                                    <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i>anuary 15, 2017</a></li>
                                                    <li><a href="#"><i className="fa fa-comments-o" aria-hidden="true"></i>09 Comments</a></li>
                                                </ul>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12"> 
                                        <ul className="post-pagination text-center">
                                            <li><a href="#"><i className='bx bxs-chevrons-left'></i></a></li>
                                            <li className="active"><a href="#">1</a></li>
                                            <li><a href="#">2</a></li>
                                            <li><a href="#">3</a></li>
                                            <li><a href="#"><i className='bx bxs-chevrons-right'></i></a></li>
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-7 col-xs-12">
                            <div className="sidebar-wrapper">
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <form className="search-form" action="#">
                                        <input placeholder="Search..." type="text"/>
                                        <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </form>
                                </div>
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" style={{padding: '.04em'}}>
                                    <div className="sec-title">
                                        <h3>Categories</h3>
                                    </div>
                                    <ul className="categories clearfix">
                                        <li><a href="#">Procedures<span>(6)</span></a></li>
                                        <li><a href="#">Transplantation<span>(2)</span></a></li>
                                        <li><a href="#">Management<span>(5)</span></a></li>
                                        <li><a href="#">Healthcare Tips<span>(10)</span></a></li>
                                        <li><a href="#">Uncategorized<span>(4)</span></a></li>
                                    </ul>
                                </div>
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <div className="sec-title">
                                        <h3>Popular Posts</h3>
                                    </div>
                                    <ul className="popular-post">
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/popular-post-1.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="title-holder">
                                                <a href="#"><h5 className="post-title">How to handle your kids’<br/> from mystery ailments</h5></a>
                                                <h6 className="post-date">June 21, 2017</h6>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/popular-post-2.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="title-holder">
                                                <a href="#"><h5 className="post-title">Lung cancer survival rate in <br/>England improves</h5></a>
                                                <h6 className="post-date">February 14, 2017</h6>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/popular-post-3.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="title-holder">
                                                <a href="#"><h5 className="post-title">Negative statin stories add <br/>to heart health risk</h5></a>
                                                <h6 className="post-date">January 17, 2017</h6>
                                            </div>
                                        </li>
                                    
                                    
                                    </ul>
                                </div>
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <div className="sec-title">
                                        <h3 className="pull-left">Instagram</h3>
                                        <a className="pull-right follow-us" href="#">Follow Us</a>
                                    </div>
                                    <ul className="instagram">
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-1.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-2.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-3.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-4.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> 
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-5.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> 
                                        <li>
                                            <div className="img-holder">
                                                <img src="/assets/img/blogs/sidebar/instagram-6.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> 
                                    </ul>
                                </div>
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <div className="sec-title">
                                        <h3>Facebook Feed</h3>
                                    </div>
                                    <div className="fb-feed">
                                        <img src="/assets/img/blogs/sidebar/facebook-feed.jpg" alt=""/>
                                    </div>     
                                </div> 
                                <div className="single-sidebar wow fadeInUp" data-wow-delay="0s" data-wow-duration="1s" data-wow-offset="0" >
                                    <div className="sec-title">
                                        <h3>Tag Cloud</h3>
                                    </div>
                                    <ul className="popular-tag">
                                        <li><a href="#">Ideas</a></li>
                                        <li><a href="#">Doctor</a></li>
                                        <li><a href="#">Experts</a></li>
                                        <li><a href="#">Tips</a></li>
                                        <li><a href="#">Health</a></li>
                                        <li><a href="#">Services</a></li>
                                        <li><a href="#">Growth</a></li>
                                    </ul>      
                                </div> 
                            </div>    
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Blogs;