import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { loginStatusAction, userInfoAction, compInfoAction, compCodeAction, loaderAction, siteDataAction, modalAction } from '../../../actions';
import { connect } from 'react-redux';
import { useFetch, ModalComponent, handleNumberInputs, logOut, customTabsButtons, createDate, getDuration, MODULES, encrypt, JQDatePicker, MyModal, getTotalCartItems } from './utilities';
import axios from 'axios';
import Menu from './menu';
import { ASTHA_ID } from '../../../constants';
import { Button } from 'react-bootstrap';
import { AutoComplete } from '../ePharma/utilities';


const Header = ({ siteData, modalAction, isLoggedIn, loginStatusAction, userInfo, userInfoAction, cart, compCode, compInfo, compInfoAction, compCodeAction, loaderAction, isHeaderActive }) => {

  const history = useHistory();

  useEffect(() => {
      return history.listen((location) => {                         // Listen for changes in history object or Url changes to toggle active menu link.
          setActiveLink(location.pathname);
          setMenuOpen(false);
      })
  },[history]) 

  useEffect(() => {
    if (window.location.hash === '#/') {               // set activeLink '/' on page load to highlight the home page link.
      setActiveLink('/');
    } else if (window.location.hash === '#/pharmacy') {
      setActiveLink('/pharmacy');
    } else if (window.location.hash === '#/labTests') {
      setActiveLink('/labTests');
    }     
  }, [])

  // const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true, decode: false });

  // useEffect(() => {
  //   if (queryString.CID && queryString?.CID !== compCode) {                     // immediate calling api's that uses CID parameter must be filtered before calling
  //     compCodeAction(queryString.CID);                                          // otherwise race condition will take place. not suitable solution is found till now.
  //     getCompanyDetails(queryString.CID);
  //   } else {
  //     getCompanyDetails(compCode);
  //   }
  // }, [queryString.CID, compCode, compCodeAction])

  // useEffect(() => {
  //   getCompanyDetails(compCode);
  // }, [compCode])

  const [activeLink, setActiveLink] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const totalCartItems = getTotalCartItems(cart);          // Add Pharmacy cart and Labtest cart items list to get total no. of cart items.   
  const [searchBoxOpen, setSearchBoxOpen] = useState(false);
  const [mobileSearchBoxOpen, setMobileSearchBoxOpen] = useState(false);
  const searchBoxRef = useRef();
  const mobileSearchBoxRef = useRef();

  // ----------------------------------------SEARCH BAR-------------------------------------------------
  const [searchTerm, setSearchTerm] = useState({query: '', filterTerm: 'All', filterId: 0});               // Avoid using null for filterId.
  const [searchList, setSearchList] = useState([]);
  const [activeListItem, setActiveListItem] = useState(0);

  useEffect(() => {
    const onKeyDown = (e) => {
        if (searchList.length === 0) return;
        if (e.keyCode === 40 && activeListItem + 1 !== searchList.length) {
            setActiveListItem(preValue => preValue + 1);
        } else if (e.keyCode === 38 && activeListItem !== 0) {
            setActiveListItem(preValue => preValue - 1);
        }                                                                          
    }
    document.body.addEventListener('keydown', onKeyDown, { capture: true });                                               
    return () => document.body.removeEventListener('keydown', onKeyDown, { capture: true });                               
  }, [searchList.length, activeListItem])

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchTerm(preValue => {
        return {...preValue, [name]: value};
    })
  }
  
  const handleSearchForm = (e) => {
    e.preventDefault();                                                             // Since searchFunction is already being continuously called using useEffect on line 158 hence we don't need to 
    history.push(`/productPage/${searchList[activeListItem].ItemId}`);              // call it when form is submitted. We just redirect the user to productPage with current active search item's id.
    setSearchList([]);
  }

  const searchItem = useCallback((query, data) => {
    if (data.isLoading) return [];
    let found = data.filter(i => i.Description.toLowerCase().includes(query.toLowerCase()));
    return found;
  }, []);

  const searchFunction = useCallback(() => {
    let filteredByItemId = searchTerm.filterId === 0 ? siteData.itemMasterCollection : siteData.itemMasterCollection.filter(i => i.SubCategoryId === searchTerm.filterId);
    const searchTerms = searchTerm.query.split(' ').filter(i => i !== '');              // Remove spaces from list to prevent searching blank spaces in item name.
    var foundItems = [];                                                                // otherwise will use [''] to search and will return all items with that have blank spaces in their names.
    searchTerms.forEach(query => {
      var searchResults = searchItem(query, filteredByItemId);
      foundItems = foundItems.concat(searchResults);
    })
    var uniqueItems = [...new Map(foundItems.map(item => [item['ItemId'], item])).values()];
    setSearchList(uniqueItems);
    // setSearchList([...new Set(foundItems)]);              // can be deleted safely.
  },[searchTerm, siteData, searchItem])

  useEffect(() => {
        searchFunction();                                            // hence empty input box will return no query to which prevents filter and trigger search.
        setActiveListItem(0);
  },[searchTerm, searchFunction])

  // ----------------------------------------SEARCH BAR-------------------------------------------------

  useEffect(() => {
    const onBodyClick = (event) => {                                                                                        
      if (searchBoxRef.current && searchBoxRef.current.contains(event.target)) return;                                      // Return if click is triggered from serach-box div and it's inner elements.
      setSearchBoxOpen(false);                                                                                              // close search-box only if click is triggered from rest of the elements (outer body).                                                                                                   // no need to use useRef because we wish to remove searchList on any clicks including
      setSearchList([]);
      if (mobileSearchBoxRef.current && mobileSearchBoxRef.current.contains(event.target)) return;                                      // Return if click is triggered from serach-box div and it's inner elements.
      setMobileSearchBoxOpen(false);                                                                                              // close search-box only if click is triggered from rest of the elements (outer body).                                                                                                   // no need to use useRef because we wish to remove searchList on any clicks including
      setSearchList([]);
    }                                                                                                                        
    document.body.addEventListener('click', onBodyClick, { capture: true });                                                // Add eventlistener on component mount.
    return () => document.body.removeEventListener('click', onBodyClick, { capture: true });                                // Remove Eventlistener on component unmount.
  }, [])

  // const getCompanyDetails = async (companyCode) => {
  //   try {      
  //     loaderAction(true);
  //     const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/CompMast?CID=${companyCode}`, {});
  //     if (res.data.COMPNAME && res.data.EncCompanyId) {
  //       compInfoAction(res.data);
  //       userInfoAction({selectedCompany: res.data});
  //     } else {
  //       alert('Invalid Company Id recieved ! Please enter correct one to continue.');
  //     }
  //   } catch (error) {
  //     alert('Something went wrong please Refresh or try after some time.');
  //     console.log('No company received. header 129');
  //   }
  //   loaderAction(false);
  // }

  // useEffect(() => {
  //   const getMembersList = async (companyCode, userId, memberId) => {
  //     if (!userId) return;
  //     try {      
  //       loaderAction(true);
  //       const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/member/Get?UserId=${userId}&CID=${companyCode}`, {});
  //       if (res.data) {
  //         const parentMember = res.data.AccPartyMemberMasterList.find(i => i.MemberId === memberId);
  //         if (parentMember) {
  //           userInfoAction({MembersList: res.data, selectedMember: parentMember});
  //         } else {
  //           userInfoAction({MembersList: res.data});
  //           console.log('No parent member found');
  //         }
  //       }
  //     } catch (error) {
  //       alert('Something went wrong please Refresh or try after some time.');
  //     }
  //     loaderAction(false);
  //   }
  //   getMembersList(compCode, userInfo.UserId, userInfo.MemberId);
  // },[loaderAction, compCode, userInfo.UserId, userInfo.MemberId, userInfoAction])

  const companyLogo = ['598','608','612','635','636','637','639','620', '659', '680', '796'];
  return (
    <>
      <header className="header" id="header">
        <span className="d-none">{activeLink}</span>
        <nav className="navbar navbar-expand-lg header-nav py-1 py-md-0">
        <div className="navbar-header">
          <ul className="nav header-navbar-rht d-flex justify-content-end align-items-center w-100">
            <li className="nav-item d-inline-flex d-lg-none">
              <span id="mobile_btn" onClick={() => setMenuOpen(true)} style={{fontSize: '0.8em'}}>
                  <span className="bar-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                  </span>
              </span>
            </li>
            <li className="nav-item me-auto px-0">
              <Link to="/" className="navbar-brand logo py-0">
                {
                  companyLogo.includes(compInfo.LogoUrl.split('.')[0]) ? 
                  <>
                    {(() => {
                      if (compCode === ASTHA_ID) {
                        return <span className='d-flex align-items-center'><img id="header-logo" src={`/img/logo/${compInfo.LogoUrl}`} className="img-fluid logo" alt={compInfo.COMPNAME}/><span style={{width: 'auto', whiteSpace: 'normal'}} className="d-none d-md-inline text-uppercase text-background"> {compInfo.COMPNAME}</span></span>  
                      } else {
                        return <img id="header-logo" src={`/img/logo/${compInfo.LogoUrl}`} className="img-fluid logo" alt={compInfo.COMPNAME}/> 
                      }
                    })()}
                  </>                  
                  :                                                                   // 15rem                                                                  
                  <span className='d-flex align-items-center'><img id="header-logo" src={'/img/logo/opd2.png'} className="img-fluid logo rounded-circle" alt={compInfo.COMPNAME}/><span style={{width: 'auto', whiteSpace: 'normal'}} className="d-none d-md-inline text-uppercase text-background"> {compInfo.COMPNAME}</span></span>                                                                                                                                                                                        
                }
              </Link>
            </li>
            <li className="nav-item d-sm-none p-0" style={{display: !isLoggedIn ? '' : 'none'}}>
                {/* <button className="nav-link header-login me-2">Login</button>
                <button className="nav-link header-login me-2">Join us</button> */}
                <Link to="#" className="dropdown-toggle nav-link align-items-center p-0 me-2" data-toggle="dropdown" aria-expanded="false">
                  <button className="nav-link header-login" style={{fontSize: 'clamp(.6em,2.8vw,0.75em)'}}>Login</button>
                </Link>
                <div className="dropdown-menu dropdown-menu-right">
                  {(() => {
                    if (compCode === ASTHA_ID) {
                      return (
                        <>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'PATIENT'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">personal_injury</span> As Patient
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'PROVIDER'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">handshake</span> As Provider
                          </span>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'PATIENT'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">personal_injury</span> As Patient
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'DOCTOR'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">stethoscope</span> As Doctor
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'PROVIDER'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">handshake</span> As Provider
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'COLLECTOR'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">approval_delegation</span> As Collector
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'POLYCLINIC'})} className="dropdown-item">
                            <i className='bx bx-clinic h3 mb-0 me-2'></i> As Polyclinic
                          </span>
                        </>
                      )
                    }
                  })()}
                </div>
            </li>
            
            <li className="nav-item d-sm-none px-1">
                <a href='https://erp.gsterpsoft.com/Login.aspx' target={'_blank'} rel="noreferrer">
                  <img src="/img/gbooks-round-logo.png" alt="Gbooks" style={{maxHeight: '2.2em', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px 0px', borderRadius: '50%'}} />
                </a>
            </li>

            <li className="nav-item dropdown has-arrow logged-item d-sm-none" style={{display: isLoggedIn ? '' : 'none'}}>
              <Link to="#" className="dropdown-toggle nav-link px-0" data-toggle="dropdown" aria-expanded="false">
                <span className="user-img">
                  <img className="rounded-circle" src="/img/user_unknown.png" width="31" alt="Darren Elder"/>
                </span>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="user-header d-flex align-items-center">
                  <div className="avatar avatar-sm">
                    <img src="/img/user_unknown.png" alt="User" className="avatar-img rounded-circle"/>
                  </div>
                  <div className="user-text">
                    <h6>{userInfo.Name}</h6>
                    {/*<p className="text-muted mb-0">Doctor</p> */}
                  </div>
                </div>
                <Link to={`/profile/${userInfo.PartyCode}`} className="dropdown-item">
                  <i className='bx bx-user-circle h3 mb-0 me-2'></i> Members
                </Link>
                <Link to='/dashboard' className="dropdown-item">
                  <i className='bx bx-tachometer h3 mb-0 me-2'></i> Services
                </Link>
                {(MODULES[compCode]?.includes('PHARMACY') && MODULES[compCode]?.includes('LAB_TEST')) || <>
                <Link className="dropdown-item" to="/myOrders"><i className='bx bx-gift h3 mb-0 me-2'></i> My Orders</Link>
                <Link className="dropdown-item" to="/cartPage"><i className='bx bx-cart-alt h3 mb-0 me-2'></i> Cart</Link>
                <Link className="dropdown-item" to="/wishlist"><i className='bx bx-heart h3 mb-0 me-2'></i> My Wishlist</Link></>}
                <span onClick={() => logOut(history)} className="dropdown-item" to="#">
                  <i className='bx bx-log-out-circle h3 mb-0 me-2'></i> Logout
                </span>
                {/* <a href='#' onClick={getLocation} className="dropdown-item" to="#">
                  <i className='bx bx-log-out-circle h3 mb-0 me-2'></i> Location
                </a> */}
              </div>
            </li>

            <li className="nav-item d-sm-none p-0">
              <span to="#" className='search-open-btn'>
                <form className='search-bar' ref={mobileSearchBoxRef} onSubmit={handleSearchForm}>
                  <div className="input-box">
                    <div className={`${mobileSearchBoxOpen ? 'results-active' : ''} ${searchList.length > 0 ? 'loaded' : ''}`}>
                      <input  id='mobile_search_input' onChange={handleSearch} value={searchTerm.query} name="query" type="text" tabIndex={1} placeholder="Enter your search key ..." />
                      <div className='search-results-1'>
                        <ul>
                            {searchList.map((i, n) => <li key={i.ItemId} className={`${activeListItem === n ? 'active' : ''}`}><Link to={`/productPage/${i.ItemId}`} onClick={() => setSearchList([])}>{i.Description}</Link></li>)}
                        </ul> 
                      </div>
                    </div>
                    <label htmlFor="mobile_search_input">
                        <i className='bx bx-search header-login ms-3' onClick={() => setMobileSearchBoxOpen(!mobileSearchBoxOpen)}></i>
                    </label>
                  </div>
                </form>
              </span>
            </li>

          </ul>

        </div>
        {/* <div className={`main-menu-wrapper ${menuOpen ? 'menu-opened' : ''}`}>
            <div className="menu-header">
                <Link to="/" className="menu-logo">
                    {
                      companyLogo.includes(compInfo.LogoUrl.split('.')[0]) ? 
                      <img src={`/img/logo/${compInfo.LogoUrl}`} className="img-fluid logo" alt={compInfo.COMPNAME}/> :
                      <img src={'/img/logo/opd2.png'} className="img-fluid logo rounded-circle" alt={compInfo.COMPNAME}/>
                    }
                    
                </Link>
                <span id="menu_close" className="menu-close" to="#" onClick={() => setMenuOpen(false)}>
                    <i className="fas fa-times"></i>
                </span>
            </div>
            <ul className="main-nav">
                <li className="" style={{display: isLoggedIn ? '' : 'none'}}>
                  <Link to="#" onClick={() => {setMenuOpen(false);navigateTo()}}>
                    <i className='bx bxs-id-card h3 mb-0 me-2 d-md-none'></i> Dashboard
                  </Link>
                </li>
                <li className='d-md-none'>
                  <Link to='/specialists' onClick={() => {setMenuOpen(false);}}>
                    <i className='bx bxs-contact h3 mb-0 me-2'></i> Specialists
                  </Link>
                </li>
                <li className="d-md-none">
                  <Link to="/cartPage" onClick={() => setMenuOpen(false)}>
                    <i className='bx bx-cart-alt h3 mb-0 me-2'></i> Cart
                  </Link>
                </li>
                <li className="d-md-none">
                  <Link to="/cartPage" onClick={() => setMenuOpen(false)}>
                    <i className='bx bxs-package h3 mb-0 me-2'></i> My Orders
                  </Link>
                </li>
            </ul>
        </div> */}
        <ul className="nav header-navbar-rht">
            {MODULES[compCode]?.includes('OPD') || <li className={`px-0 py-1 tab-btn big-menu ${activeLink === '/specialists' ? 'active': ''}`}>        {/* Toggle activeLink by directly tracking the hash. */}
                <Link className='nav-link' to="/specialists" style={{fontSize: '0.9em', padding: '0.6em 0.7em'}}>
                    OPD SERVICES
                    <hr style={{"margin": "0", "marginTop": "2px", "background": "#0000004f"}} />
                    <span className="d-block mt-1" style={{"fontWeight": "500", "fontSize": "0.84em"}}>Book Appointments</span>
                </Link>
            </li>}
            {/* {MODULES[compCode]?.includes('PHARMACY') || <li className={`px-0 py-1 tab-btn big-menu ${activeLink === '/pharmacy' ? 'active': ''}`} style={{display: 'inline-flex'}}>
                <Link className='nav-link' to="/pharmacy" style={{fontSize: '0.9em', padding: '0.6em 0.7em'}}>
                    PHARMACY
                    <hr style={{"margin": "0", "marginTop": "2px", "background": "#0000004f"}} />
                    <span className="d-block mt-1" style={{"fontWeight": "500", "fontSize": "0.84em"}}>Medicines & Products</span>
                </Link>
            </li>} */}
            {MODULES[compCode]?.includes('LAB_TEST') || <li className={`px-0 py-1 tab-btn big-menu ${activeLink === '/labTests' ? 'active': ''}`}  style={{display: 'inline-flex'}}>
                <Link className='nav-link' to="/labTests" style={{fontSize: '0.9em', padding: '0.6em 0.7em'}}>
                    LAB TESTS
                    <hr style={{"margin": "0", "marginTop": "2px", "background": "#0000004f"}} />
                    <span className="d-block mt-1" style={{"fontWeight": "500", "fontSize": "0.84em"}}>Health check-ups</span>
                </Link>
            </li>}
            {/* <li className="nav-item contact-item">
              <div className='d-flex align-items-center'>
                <div className="header-contact-img">
                    <i className="fas fa-map-marker text-dark"></i>
                </div>
                <div className="header-contact-detail">
                    <p className="contact-header">Location</p>
                    <p className="contact-info-header"> Select Location</p>
                </div>
              </div>
              <form className="hm-searchbox" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>
                <input name="query" type="text" placeholder="Enter your search key ..." autoComplete='off'/>
                <button className="li-btn" type="submit"><i className="fa fa-search text-white"></i></button>
                <AutoComplete name='search-rersults' list={[]} isLoading={true} setActive={() => {}} children={<Button />} keyName={'ItemId'}/>
              </form>
            </li> */}

            {(MODULES[compCode]?.includes('PHARMACY') && MODULES[compCode]?.includes('LAB_TEST')) || <li className="nav-item">
                <Link to='/cartPage' className='' onClick={() => setMenuOpen(false)}>
                  <i className='bx bx-cart-alt h2 mb-0 mt-2 position-relative'>
                    <span id="cart-badge" className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{fontSize: '11px', fontFamily: 'Poppins', display: totalCartItems > 0 ? 'block' : 'none'}}>{totalCartItems}</span>
                  </i>
                </Link>
            </li>}
            
            <li className="nav-item">
              <span to="#" className='search-open-btn'>
                <form className='search-bar' ref={searchBoxRef} onSubmit={handleSearchForm}>
                  <div className="input-box">
                    <div className={`${searchBoxOpen ? 'results-active' : ''} ${searchList.length > 0 ? 'loaded' : ''}`}>
                      <input onChange={handleSearch} value={searchTerm.query} name="query" type="text" id='search_input' tabIndex={1} placeholder="Enter your search key ..." />
                      <div className='search-results-1'>
                        <ul>
                            {searchList.map((i, n) => <li key={i.ItemId} className={`${activeListItem === n ? 'active' : ''}`}><Link to={`/productPage/${i.ItemId}`} onClick={() => setSearchList([])}>{i.Description}</Link></li>)}
                        </ul> 
                      </div>
                    </div>
                      <label htmlFor="search_input">
                          <i className='bx bx-search header-login ms-3' onClick={() => setSearchBoxOpen(!searchBoxOpen)}></i>
                      </label>
                  </div>
                </form>
              </span>
            </li>

            {/* <li className="nav-item" style={{display: isLoggedIn ? 'none' : ''}}>
                <button className="nav-link header-login" to="#" onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'POLYCLINIC'})}>Join us</button>
            </li> */}

                                            {/* has-arrow */}
            <li className="nav-item dropdown logged-item" style={{display: isLoggedIn ? 'none' : ''}}>
              <Link to="#" className="dropdown-toggle nav-link align-items-center" data-toggle="dropdown" aria-expanded="false">
                <button className="nav-link header-login" style={{fontSize: 'clamp(.6em,2.8vw,0.75em)'}}>Login</button>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
              {(() => {
                    if (compCode === ASTHA_ID) {
                      return (
                        <>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'PATIENT'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">personal_injury</span> As Patient
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'PROVIDER'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">handshake</span> As Provider
                          </span>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'PATIENT'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">personal_injury</span> As Patient
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'DOCTOR'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">stethoscope</span> As Doctor
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'PROVIDER'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">handshake</span> As Provider
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'COLLECTOR'})} className="dropdown-item">
                            <span className="material-symbols-outlined h3 mb-0 me-2">approval_delegation</span> As Collector
                          </span>
                          <span onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'POLYCLINIC'})} className="dropdown-item">
                            <i className='bx bx-clinic h3 mb-0 me-2'></i> As Polyclinic
                          </span>
                        </>
                      )
                    }
                  })()}
              </div>
            </li>



            {/* <li className="nav-item" style={{display: isLoggedIn ? 'none' : ''}}>
                <button className="nav-link header-login" to="#" onClick={() => modalAction('LOGIN_MODAL', true, {mode: 'PATIENT'})}>Login </button>
            </li> */}
            <li className="nav-item">
                {/* <a href='https://erp.gsterpsoft.com/Login.aspx' target={'_blank'} rel="noreferrer"><i className="bx bx-desktop header-login" style={{fontSize: '18px', cursor: 'pointer'}}></i></a> */}
                <a href='https://erp.gsterpsoft.com/Login.aspx' target={'_blank'} rel="noreferrer">
                  <img src="/img/gbooks-round-logo.png" alt="Gbooks" style={{maxHeight: '2.2em', boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px 0px', borderRadius: '50%'}} />
                </a>
            </li>
            <li className="nav-item dropdown has-arrow logged-item" style={{display: isLoggedIn ? '' : 'none'}}>
              <Link to="#" className="dropdown-toggle nav-link align-items-center" data-toggle="dropdown" aria-expanded="false">
                <span className="user-img">
                  <img className="rounded-circle" src="/img/user_unknown.png" width="31" alt="Darren Elder"/>
                </span>
                <h6 className="ms-2 mb-0">{userInfo.Name}</h6>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="user-header d-flex align-items-center">
                  <div className="avatar avatar-sm">
                    <img src="/img/user_unknown.png" alt="User" className="avatar-img rounded-circle"/>
                  </div>
                  <div className="user-text">
                    <h6>{userInfo.Name}</h6>
                    {/*<p className="text-muted mb-0">Doctor</p> */}
                  </div>
                </div>
                <Link className="dropdown-item" to={`/profile/${userInfo.PartyCode}`}>
                  <i className='bx bx-user-circle h3 mb-0 me-2'></i> Members
                </Link>
                <Link className="dropdown-item" to="/dashboard">
                  <i className='bx bx-tachometer h3 mb-0 me-2'></i> Services
                </Link>
                {(MODULES[compCode]?.includes('PHARMACY') && MODULES[compCode]?.includes('LAB_TEST')) || <>
                <Link className="dropdown-item" to="/myOrders"><i className='bx bx-gift h3 mb-0 me-2'></i> My Orders</Link>
                <Link className="dropdown-item" to="/cartPage"><i className='bx bx-cart-alt h3 mb-0 me-2'></i> Cart</Link>
                <Link className="dropdown-item" to="/wishlist"><i className='bx bx-heart h3 mb-0 me-2'></i> My Wishlist</Link></>} 
                <span onClick={() => logOut(history)} className="dropdown-item" to="#">
                  <i className='bx bx-log-out-circle h3 mb-0 me-2'></i> Logout
                </span>
                {/* <a href='/' className="dropdown-item" to="#">
                  <i className='bx bx-log-out-circle h3 mb-0 me-2'></i> Logout
                </a> */}
              </div>
            </li>
        </ul>
      </nav>
      <div className={`menu-backdrop ${menuOpen ? 'active' : ''}`} onClick={() => setMenuOpen(false)}></div>
    </header>
    <div className={`main-menu-wrapper ${menuOpen ? 'menu-opened' : ''}`}>
    <div className="menu-header">
            <Link to="/" className="menu-logo">
                {
                  companyLogo.includes(compInfo.LogoUrl.split('.')[0]) ? 
                  <img src={`/img/logo/${compInfo.LogoUrl}`} className="img-fluid logo" alt={compInfo.COMPNAME}/> :
                  <img src={'/img/logo/opd2.png'} className="img-fluid logo rounded-circle" alt={compInfo.COMPNAME}/>
                }
            </Link>
            <span id="menu_close" className="menu-close" to="#" onClick={() => setMenuOpen(false)}>
                <i className="fas fa-times"></i>
            </span>
        </div>
        {isHeaderActive && <Menu isLoggedIn={isLoggedIn} />}
    </div>
    </>
  )
}


const mapStateToProps = (state) => {
  return { compCode: state.compCode, compInfo: state.compInfo, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, cart: state.cart, siteData: state.siteData, isHeaderActive: state.isHeaderActive };
}

export default connect(mapStateToProps, {loginStatusAction, siteDataAction, userInfoAction, compInfoAction, compCodeAction, loaderAction, modalAction})(Header);
