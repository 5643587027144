import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { cartAction, userInfoAction, loaderAction, modalAction } from '../../../actions';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { getFormattedDate, ModalComponent, handleNumberInputs, BreadCrumb, wait, bookingToast } from './utilities';
import { Link, useHistory } from 'react-router-dom';
import LabTests from './labTests';
// import { ConnectedCartCard } from './cards';


const Checkout = ({ cart, cartAction, compCode, userInfo, userInfoAction, loaderAction, isLoggedIn, globalData, modalAction }) => {
                   
  const history = useHistory();
  // const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [orderData, setOrderData] = useState({
    PartyCode: '',
    InsBy: '',              
    PaymentMethod: 'COD',
    Amount: '',
    EncCompanyId: '',
    SalesDetailsList: [],                                // {ItemId: '', Qty: '', Unit: '', MRP: '', MRPOnDisPer: '', Rate: ''}

    BillingState: userInfo.State,
    BillingAddress: userInfo.Address + userInfo.Address2 + userInfo.Pin,
    DeliveryParty: userInfo.PartyCode,
    DeliveryState: userInfo.State,
    DeliveryAddress : userInfo.Address + userInfo.Address2 + userInfo.Pin
  });

  const breadCrumbData = {
    links: [{name: 'Home', link: '/'}, {name: 'Cart', link: '/cartPage'}],
    activeLink: '/cartPage'
  }

  const cartArray = Object.values(cart.pharmacy);                                                               // Convert cart object into list.
  const cartArrayLength = cartArray.length;

  let totalItemsValue = cartArray.map(i => i.SRate * i.Qty);
  let totalCartValue = totalItemsValue.reduce((total, num) => total + num, 0).toFixed(2);
  // const cartItemsValueList = cartArray.map(item => item.Qty * item.SRate);                                              // Array of all item's price * quantity selected.
  // const cartSubtotal = cartItemsValueList.reduce((total, num) => total + num, 0).toFixed(2);                            // Reducing to get sum of cartItemsValueList.

  let orderList = useMemo(() => Object.values(cart.pharmacy).map(i => ({                                                         // since we need value (array) from orderList hence using useMemo instead of useCallback which return function. both can suppress useEffect dependency warning.
    BillQty: i.Qty,                                                     // We can't use cartArray defined above. because it cartArray recalculated on every page render and usecallback thinks it's been changed  
    ItemId: i.ItemId,                                                     // and hence triggers recalculation / re-run of orderList function which makes below useEffect to reRender the page (again recalculates cartArray) resulting falling in a loop.
    Unit: i.Unit,
    MRP: i.ItemMRP,
    MRPOnDisPer: i.DiscountPer,
    Rate: (((i.Qty * i.SRate) - (((i.Qty * i.SRate * i.IGSTRATE) / (i.IGSTRATE + 100))))/i.Qty).toFixed(2),
    PackSizeId: i.PackSizeId ? i.PackSizeId : 0,
    Amount: i.Qty * i.SRate,
    TaxableAmount: ((i.Qty * i.SRate) - ((i.Qty * i.SRate * i.IGSTRATE) / (i.IGSTRATE + 100))).toFixed(2),
    CGSTRATE: i.CGSTRATE,
    SGSTRATE: i.SGSTRATE,
    IGSTRATE: i.IGSTRATE
  })),[cart.pharmacy])

  useEffect(() => {
    async function init () {
      if (isLoggedIn) {
        setOrderData((preValues) => ({
            ...preValues,
            PartyCode: userInfo.PartyCode,
            InsBy: userInfo.UserId,              
            PaymentMethod: 'COD',
            Amount: totalCartValue,
            EncCompanyId: compCode,
            SalesDetailsList: orderList,                       
            BillingState: userInfo.State,
            BillingAddress: userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
            DeliveryParty: userInfo.PartyCode,
            DeliveryState: userInfo.State,
            DeliveryAddress : userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
            LocationId: globalData.location.LocationId
        }))
      } else {
        setOrderData((preValues) => ({
            ...preValues,
            PartyCode: '0',
            InsBy: '0',
            BillingState: '',
            BillingAddress: '',
            DeliveryParty: '',
            DeliveryState: '',
            DeliveryAddress : '',              
        }))
      }
    }
    init();
  },[isLoggedIn, userInfo, totalCartValue, compCode, orderList])

  const placeOrder = async () => {
    console.log(orderData);
    try {
        loaderAction(true);
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Pharma`, orderData);
        loaderAction(false);
        console.log(res.data); 
        bookingToast(res.data, { position: "top-center", autoClose: 4000, closeButton: false, className: 'booking-reference-toast' });
        await wait(3000);
        history.push('/myOrders');
        cartAction('DUMP_CART', {}, 'pharmacy');
        // updateLocalStorageItems();
        // modalAction('ORDER_SUCCESS_MODAL', true);
    } catch (err) {
        console.log(err);
        return false;
    }
  }

  const handleCheckout = () => {
    if (!isLoggedIn) return modalAction('LOGIN_MODAL', true, {mode: 'PATIENT'});
    if (!orderData.LocationId) return alert('Please select a Service Location before making an order.');
    if (!orderData.SalesDetailsList.length) return alert('Add something to your cart before making an order');
    placeOrder();
  }

  return (
    <div id="cartPage">
      <BreadCrumb data={breadCrumbData}/>
      {cartArray.length === 0 && <EmptyCart />}
      <div className="cartPageSection container-fluid content px-3 px-lg-5" >    
        <div className="row">
          <div className="col col-12 col-lg-6">
            {/* {cartArray.length !== 0 && <CartPageSection heading={`${cartArray.length} items`} cartData={cartArray} compCode={compCode} userInfo={userInfo} userInfoAction={userInfoAction}/>} */}
            {cartArrayLength !== 0 && <OrderSummary isLoggedIn={isLoggedIn} cartArray={cartArray} totalCartValue={totalCartValue} userInfo={userInfo}/>}
          </div>
          <div className="col col-12 col-lg-6">
            {cartArrayLength !== 0 && <DeliverSummary isLoggedIn={isLoggedIn} cartArray={cartArray} totalCartValue={totalCartValue} handleCheckout={handleCheckout} userInfo={userInfo} modalAction={modalAction}/>}
          </div>
        </div>
      </div>
      {/* <CheckoutForm isActive={showCheckoutForm} setShowForm={setShowCheckoutForm} cart={cart} compCode={compCode} totalCartValue={totalCartValue} cartAction={cartAction} loaderAction={loaderAction} isLoggedIn={isLoggedIn} userInfo={userInfo} userInfoAction={userInfoAction}/> */}
      <div className="section-header text-center">
          <h2 style={{"borderBottom": "2px solid gray", "textTransform": "uppercase", "display": "inline", "letterSpacing": "3px"}}>Related Products</h2>
      </div>
      <LabTests/>
    </div>
  )
}

const mapStateToPropsTwo = (state) => {
  return { cart: state.cart, compCode: state.compCode, userInfo: state.userInfo, isLoggedIn: state.isLoggedIn, globalData: state.globalData };
}

export default connect(mapStateToPropsTwo, {cartAction, userInfoAction, loaderAction, modalAction})(Checkout);


// const CartPageSection = ({ cartData, heading }) => {

//   return (
//     <div className="card border-0 w-100" style={{minHeight: '17.7rem'}}>
//       <h3 className="card-header border-info bg-transparent">Cart Overview <span style={{fontSize: '0.7rem'}}>({heading})</span></h3>             
//       <div className="card-body">
//         <div className="cart-list-wrapper">
//           {cartData.map(i => <ConnectedCartCard item={i} key={i.ItemId}/>)}
//         </div>
//       </div>
//       <div className="card-footer border-info bg-transparent">Date: {getFormattedDate()}</div>
//     </div>
//   )
// }

const OrderSummary = ({ totalCartValue, cartArray, userInfo, isLoggedIn }) => {

  const [paymentMethod, setPaymentMethod] = useState(1);

  const payMethods = { 1: 'Cash on Delivery', 2: 'Pay Online', 3: 'Others' };

  const deliveryCharges = 50;

  return (
    <div className="card border-0 w-100 order-summary" style={{minHeight: '17.7rem'}}>
      <h3 className="card-header border-info bg-transparent">Order Summary</h3>
      <div className="card-body">
        <div className='product-list'>
          <ul>
            {cartArray.map(i => <li key={i.ItemId}><div>{i.Description}<i className='bx bx-x'></i>{i.Qty}</div> ₹ {(i.Qty*i.SRate).toFixed(2)}</li>)}
          </ul>
        </div>
        <div className="checkout-details">
          {/* <div>
            <h4 className="card-title">Deliver to</h4>
            <h5 className="card-text">{isLoggedIn ? userInfo.Name : 'Please Login'}</h5>
          </div>
          <div className='checkout-address'>
            <div>
              <h4 className="card-title">Delivery Address</h4>
              <button type="button" className="btn payment-btn btn-primary">{isLoggedIn ? 'Change Address' : 'Add Address'}</button>
            </div>
            <span>{isLoggedIn ? userInfo.Address : 'Please login to add an address.'}</span>
          </div> */}
          <div>
            <h4 className="card-title">Cart Subtotal</h4>
            <h5 className="card-text">₹ {totalCartValue}</h5>
          </div>
          <div>
            <h4 className="card-title">Delivery Charges</h4>
            <h5 className="card-text">Free Delivery</h5>
          </div>
          <div>
            <h4 className="card-title">Order Total</h4>
            <h5 className="card-text">₹ {parseFloat(totalCartValue)}</h5>
          </div>
          {/* <div>
            <h4 className="card-title">Payment Method</h4>
            <div className="dropdown">
              <button type="button" className="btn dropdown-toggle payment-btn" id="dropdownButton" data-bs-toggle="dropdown" aria-expanded="false">{payMethods[paymentMethod]}</button>
              <div className="dropdown-menu" aria-labelledby="dropdownButton">
                {Object.keys(payMethods).map((i, n) => <span key={n} className="dropdown-item" onClick={() => setPaymentMethod(i)}>{payMethods[i]}</span>)}
              </div>
            </div>
          </div> */}
        </div>
        {/* <button className="btn btn-primary w-100 mt-3 py-2 checkout-btn">PLACE ORDER</button> */}
        {/* <div className='d-flex gap-3 justify-content-center'>
          <Link to={'/wishlist'} className="btn btn-primary mt-3 py-2 checkout-btn" style={{flex: 1}}>WISHLIST</Link>
          <Link to={'/cartPage'} className="btn btn-primary mt-3 py-2 checkout-btn" style={{flex: 1}}>VISIT CART</Link>
        </div> */}
        <div className="btn-box mt-3" style={{fontSize: '1.7em'}}>
            <Link to={'/wishlist'} className="btn btn-main add-wishlist-btn">WISHLIST</Link>
            <Link to={'/cartPage'} className="btn btn-main">VISIT CART</Link>
        </div>
      </div>
      <div className="card-footer border-info bg-transparent">Created DD-MM-YYYY</div>
    </div>
  )
}

const DeliverSummary = ({ totalCartValue, handleCheckout, cartArray, userInfo, isLoggedIn, modalAction }) => {

  const [paymentMethod, setPaymentMethod] = useState(1);

  const payMethods = { 1: 'Cash on Delivery', 2: 'Pay Online', 3: 'Others' };

  const deliveryCharges = 50;

  return (
    <div className="card border-0 w-100 order-summary" style={{minHeight: '17.7rem'}}>
      <h3 className="card-header border-info bg-transparent">Delivery Summary</h3>
      <div className="card-body pt-2">
        {/* <div className='product-list'>
          <ul>
            {cartArray.map(i => <li key={i.SRate}><div>{i.Description}<i className='bx bx-x'></i>{i.Qty}</div> ₹ {(i.Qty*i.SRate).toFixed(2)}</li>)}
          </ul>
        </div> */}
        <div className="checkout-details">
          <div>
            <h4 className="card-title">Deliver to</h4>
            <h5 className="card-text">{isLoggedIn ? userInfo.Name : 'Please Login'}</h5>
          </div>
          <div>
            <h4 className="card-title">Member</h4>
            <h5 className="card-text">{userInfo.selectedMember.MemberName} <Link to={`#`} onClick={() => modalAction('MEMBER_SELECT_MODAL', true)} className='dashboard-card__btn-box-item ms-2' style={{'--clr': '#E80202', '--bg': '#ffbcbc63', '--bClr': '#ff33333d', fontSize: '0.8em'}}>CHANGE</Link></h5>
          </div>
          <div>
            <h4 className="card-title">Phone Number</h4>
            <h5 className="card-text">{isLoggedIn ? userInfo.RegMob1 : 'Please Login'}</h5>
          </div>
          <div className='checkout-address'>
            <div>
              <h4 className="card-title">Delivery Address</h4>
              <button type="button" className="btn payment-btn btn-primary" onClick={() => modalAction('EDIT_USER_MODAL', true)}>{isLoggedIn ? 'Change Address' : 'Add Address'}</button>
            </div>
            <span>{isLoggedIn ?  `${userInfo.Address2 ? (userInfo.Address2 + ', '): ''} ${userInfo.Address}${userInfo.Pin ? (', Pin code - ' + userInfo.Pin): ''}` : 'Please login to add an address.'}</span>
          </div>
          {/* <div>
            <h4 className="card-title">Cart Subtotal</h4>
            <h5 className="card-text">₹ {totalCartValue}</h5>
          </div>
          <div>
            <h4 className="card-title">Delivery Charges</h4>
            <h5 className="card-text">Free Delivery</h5>
          </div>
          <div>
            <h4 className="card-title">Order Total</h4>
            <h5 className="card-text">₹ {parseFloat(totalCartValue)}</h5>
          </div> */}
          <div>
            <h4 className="card-title">Payment Method</h4>
            <div className="dropdown">
              <button type="button" className="btn dropdown-toggle payment-btn" id="dropdownButton" data-bs-toggle="dropdown" aria-expanded="false">{payMethods[paymentMethod]}</button>
              <div className="dropdown-menu" aria-labelledby="dropdownButton">
                {Object.keys(payMethods).map((i, n) => <span key={n} className="dropdown-item" onClick={() => setPaymentMethod(i)}>{payMethods[i]}</span>)}
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-primary w-100 mt-3 py-2 checkout-btn" onClick={handleCheckout}>PLACE ORDER</button>
      </div>
      <div className="card-footer border-info bg-transparent">Created DD-MM-YYYY</div>
    </div>
  )
}

const EmptyCart = () => (
    <div className="container-fluid content emptyCart">
    <div className="row mx-md-4">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
          <h5 className="mb-0">Cart</h5>
          </div>
          <div className="card-body cart">
            <div className="col-sm-12 empty-cart-cls text-center">
              <img src="/img/emptyCart.png" width="130" height="130" className="img-fluid mb-4 mr-3" alt="empty_cart" style={{transform: 'translateX(-11px)'}}/>
              <h3><strong>Your Cart is Empty</strong></h3>
              <h4>Add something to make me happy :)</h4>
              <Link to="/labTests" className="btn btn-primary cart-btn-transform m-3 py-2 px-3" data-abc="true">Continue Shopping</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)


// const CheckoutForm = ({ isActive, setShowForm, userInfoAction, userInfo, isLoggedIn }) => {

//   const [statesList, setStatesList] = useState([{Description: 'West Bengal', CodeId: 3}]); 
//   // const [otp, setOTP] = useState({isOpen: false, recievedValue: 'null', enteredValue: '', sent: false, verified: false});
//   const [userData, setUserData] = useState({
//     Address: '',
//     Address2: '',
//     City: '',
//     State: 3,
//     Pin: '',
//   })

//   useEffect(() => {
//     async function init () {

//       loaderAction(true);
//       const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Values/1`);
//       loaderAction(false);
//       let states = res.data.map(i => ({Description: i.Description, CodeId: i.CodeId}));
//       setStatesList(states);

//       // if (isLoggedIn) {
//       //   setOTP((otp) => ({...otp, verified: true}));              // Bypass all signin and otp related stuff if user is already logged in.
//       // } else {
//       //   setOTP({isOpen: false, recievedValue: 'asdfasdasdf', enteredValue: '', sent: false, verified: false, read_only: false});          // hide form submit button.
//       // }
//       // setUserData(pre => ({                                 
//       //   ...pre,
//       //   Address: userInfo.Address,
//       //   Address2: userInfo.Address2,
//       //   City: userInfo.City,
//       //   State: userInfo.State,                                              
//       //   Pin: userInfo.Pin,                                   
//       // }))
//     }
//     init();
//   },[isLoggedIn])

//   const [showAlert, setShowAlert] = useState(false);
//   const [refNumber, setRefNumber] = useState('');

//   const handleFormInput = (e) => {
//     const { name, value } = e.target;
//     setUserData(preValue => {
//       return {...preValue, [name]: value};
//     })
//   };

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();
//     if (isLoggedIn) {
//       if (userData.RegMob1.length < 10) return alert('please enter a valid phone number.');      
//       let status = await makeRegisterationRequest(userData);
//       if (status) {
//           let loginStatus = await refreshUserInfo(userData);
//           if (loginStatus) {
//               setShowForm(false);
//           } else {
//               alert('Something went wrong. Please try again later.');
//           }
//       } else {
//           alert('We could not update your address. Please try again later.');
//       }
//       console.log(userData);
//     } else {
//         alert('Please Login first to Edit your profile.');
//     }
//   }

//   const makeRegisterationRequest = async (params) => {
//     console.log(params);
//     try {
//         loaderAction(true);
//         const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/UserReg`, params);
//         loaderAction(false);
//         console.log(res.data);
//         if (res.data[0] === 'Y') { 
//             return true;
//         } else {
//             return false;
//         }     
//     } catch (err) {
//         console.log(err);
//         return false;
//     }
//   } 

//   const refreshUserInfo = async (params) => {
//     try {
//         loaderAction(true);
//         const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth?UN=${params.RegMob1}&UP=${params.UserPassword}&CID=${compCode}`);
//         loaderAction(false);
//         if (res.data.UserId === 0) {
//           return false;
//         } else {
//           console.log(res.data);
//           userInfoAction('ADD_USER_INFO', res.data);
//           return true;
//         }
//     } catch (err) {
//         alert(err)
//     }
// }

//   const renderCheckoutModal = () => {
//     return (
//       <div className="row">
//         <form className="bg-white rounded" onSubmit={handleFormSubmit}>
//             <div className="col-md-12">
//             <div className="row gx-2">
//               <h4 className="card-title">Personal Information:-</h4>
//               <div className="col-md-6 col-sm-12">
//                 <div className="form-group form-focus focused">
//                   <label className="focus-label">Name</label>
//                   <input name="Name" value={userData.Name} onChange={handleFormInput} readOnly className="form-control floating" tabIndex={1} type="text" autoComplete="off"/>
//                   <input type="hidden" defaultValue={0} />
//                 </div>
//               </div>
//               <div className="col-md-6 col-sm-12">
//                 <div className="form-group form-focus focused" id="lblMobile1">
//                   <label className="focus-label">Mobile No.(1)</label>
//                   <input name="RegMob1" value={userData.RegMob1} readOnly className="form-control floating" tabIndex={1} maxLength={10} />
//                 </div>
//               </div>
//             </div>0

//             <div className="row gx-2">
//               <div className="col-md-6 col-sm-12">
//                 <div className="form-group form-focus focused" id="lblMobile1">
//                   <label className="focus-label">Address</label>
//                   <input name="Address" value={userData.Address} onChange={handleFormInput} required className="form-control floating" tabIndex={1} />
//                 </div>
//               </div>

//               <div className="col-md-6 col-sm-12">
//                 <div className="form-group form-focus focused">
//                   <label className="focus-label">Apartment / Flat no. (optional)</label>
//                   <input name="Address2" value={userData.Address2} onChange={handleFormInput} className="form-control floating" autoComplete="off"/>
//                 </div>
//               </div>
//             </div>

//             <div className="row gx-2">
//               <div className="col-md-4 col-sm-12">
//                 <div className="form-group form-focus focused">
//                   <label className="focus-label">State</label>
//                   <select name="State" value={userData.State} onChange={handleFormInput} required id="ddlGender" tabIndex={1} className="form-control floating">
//                     <option value="">-Select-</option>
//                     {statesList.map(item => {
//                       return (
//                         <option key={item.CodeId} value={item.CodeId}>{item.Description}</option>
//                       )
//                     })}
//                   </select>
//                 </div>
//               </div>
//               <div className="col-md-4 col-sm-12">
//                 <div className="form-group form-focus focused">
//                   <label className="focus-label">City</label>
//                   <input name="City" value={userData.City} onChange={handleFormInput} required className="form-control floating" tabIndex={1} type="text" id="txtAddress" />
//                 </div>
//               </div>
//               <div className="col-md-4 col-sm-12">
//                 <div className="form-group form-focus focused">
//                   <label className="focus-label">Pin Code</label>
//                   <input name="Pin" value={userData.Pin} onChange={(e) => handleNumberInputs(e, setUserData)} className="form-control floating" tabIndex={1} type='text' maxLength={6} />
//                 </div>
//               </div>
//             </div>

//             <button type="submit" className="btn btn-primary btnSave mx-auto fw-bold" style={{minWidth: "10rem", display: 'block'}} tabIndex={1} id="btnSave">SUBMIT</button>
//           </div>
//         </form>
//       </div>
//     )
//   }

//   return (
//     <>
//       <div className={`alert alert-dismissible pe-none fade ${showAlert ? 'show pe-auto' : "" }`} style={{position: "fixed", top: "0", left: "0", bottom: 0, right: 0, zIndex: 4, display: "grid", placeItems: "center", backgroundColor: 'rgba(0, 0, 0, 0.49)'}}>
//         <Alert show={showAlert} variant="success">
//           <Alert.Heading>Thank You for Booking.</Alert.Heading>
//             <p>Please keep your Reference No: <span className="text-danger">{refNumber}</span>.</p>
//             <div className="d-flex justify-content-end">
//               <Button onClick={() => setShowAlert(false)} variant="outline-success">
//                 CLOSE
//               </Button>
//             </div>
//         </Alert>
//         {!showAlert && <Button onClick={() => setShowAlert(true)}>Show Alert</Button>}
//       </div>
//       <ModalComponent isActive={isActive} heading={`Checkout`} child={renderCheckoutModal()} handleClose={setShowForm}/>
//     </>
//   );
// }
