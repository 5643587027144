import React from 'react';
import RenderCarousel from "../../carousel";

function SliderSection({ children, data, heading, responsive, autoPlay=true }) {

  const items = data.map((item, index) => {
    return (
      <div key={index} style={{"display": "grid", "placeItems": "center"}}>
          {React.cloneElement(children, { data: item, key: index })}
          {/* {children({data: item, key: index})} */}
      </div>      
    )
  })

  const defaultResponsive = {
    0: {
      items: 1
    },
    550: {
      items: 2
    },
    768: {
      items: 3
    },
    992: {
      items: 4
    },
    1200: {
      items: 5
    },
    1500: {
      items: 5
    }
	}

  return (
    <>
      <div className="my-4 d-none" style={{"borderBottom": "1px solid #09dca4"}}>
        <img src="/img/urology.png" alt="urology" style={{"height": "2rem", "marginRight": "0.5rem"}}/>
        <h3 style={{"lineHeight": "2rem"}}>{heading}</h3>
      </div>
        <RenderCarousel
          data={items} 
          responsive={responsive ? responsive : defaultResponsive}
          autoPlay={false}
          stopAutoPlayOnHover={true}
          infinite={true}
          autoPlayInterval={1500}
        />
    </>
  );
}

export default SliderSection;
