import React, { useCallback, useEffect, useState } from 'react';
import { ConnectedLabTestCard, ConnectedPackageCard } from './cards';
import { SliderSectionM } from './mobileView/homeM';
import SliderSection from './sliderSection';
import { BreadCrumb, getFrom, HeroSlider, ProductSlider, Spinner } from './utilities';
import { connect } from 'react-redux';
import { siteDataAction, userInfoAction } from '../../../actions';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';


const LabTests = ({ siteData, siteDataAction, compCode, isMobile, mode='', globalData, userInfo, compInfo, userInfoAction }) => {

  const [activeCompany, setActiveCompany] = useState({});
  const [labData, setLabData] = useState({loading: true, data: {ParentCategoryList: [], LinkSubCategoryList: [], itemMasterCollection: []}, err: {status: false, msg: ''}});
  const [companyTabList, setCompanyTabList] = useState({loading: true, data: [], err: {status: false, msg: ''}});
  const [investigationItem, setInvestigationItem] = useState({});

  const breadCrumbData = {
    links: [{name: 'Home', link: '/'}, {name: 'Labtests', link: '/labtests'}],
    activeLink: '/labtests'
  }

  useEffect(() => {
    const getCompanyList = async (companyCode, userId) => {                  
      if (!companyCode) return console.log('no companyCode received');               
      const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/CompMast?CID=${companyCode}&UID=${userId}`, {}, setCompanyTabList);
      if (res) {                                                               
        setCompanyTabList(res);                
      } else {
        alert('Something went wrong.');
      }
    }
    getCompanyList(compCode, userInfo.UserId);
  },[compCode, userInfo.UserId])

  // useEffect(() => {
  //   if (userInfo.selectedCompany.EncCompanyId) return setActiveCompany(userInfo.selectedCompany);
  //   setActiveCompany(compInfo);          
  // },[compInfo])

  useEffect(() => {
    if (!companyTabList.data.length) return;
    if (userInfo.selectedCompany.EncCompanyId) {
      setActiveCompany(userInfo.selectedCompany);     
    } else {
      alert('Something went wrong. 47');
    }
  }, [userInfo.selectedCompany.EncCompanyId, companyTabList.data.length])

  useEffect(() => {
    const getLabData = async (company) => {                
      if (!company.EncCompanyId) return console.log('no companyCode received');                 
      if (!company.LocationId) return console.log('no Loc Id received'); 
      console.log(company.EncCompanyId);      
      const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/Pharma?CID=${company.EncCompanyId}&LOCID=${company.LocationId}&CatType=INVESTIGATION`, {}, setLabData);
      if (res) {              
        setLabData(res);
        setInvestigationItem(res.data.ParentCategoryList[0]);
      } 
    }
    getLabData(activeCompany);
    setSearchItem({name: ''});
  },[activeCompany.EncCompanyId])
  
  const renderSlider = (data, parentId) => {    
    const productCategoryItems = data.data.itemMasterCollection.filter(i => i.Category === parentId);   
    const parentCategoryName = data.data.ParentCategoryList.filter(i => i.Value === parentId.toString())[0]?.Text;
    if (data.loading) {
      return <Skeleton count={10}/>;
    } else if (productCategoryItems.length === 0) {
      return; // <div className='text-center my-5 w-100'><h2 className="text-info mark">No Products found !</h2></div>;
    } else {
      // const products = (list) => list.map(item => (<div key={item.ItemId}><ConnectedLabTestCard data={item} /></div>));
      return (
        <div>
          <div className='cat-heading'>
              <h4 className='mb-0'>{parentCategoryName}</h4>
              <span>View All</span>
          </div>
          <div className='pt-2 pt-md-0' style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', gap: '1.5em 2em', padding: '0 0.5em 0.5em'}}>
              {/* <ProductSlider dataList={products(productCategoryItems)}/> */}
              {productCategoryItems.map(item => (<ConnectedLabTestCard data={item} key={item.ItemId} />))}
          </div>
        </div>
      )
    }
  }

  const handleDropDown = (id) => {
    let selected = companyTabList.data.find(i => i.EncCompanyId === id);
    setActiveCompany(selected);
    userInfoAction({ selectedCompany: selected });
  }

  const heroCarousel = () => {
    return [1,2].map(item => (
      <div key={item}>
        <img className='img-fluid w-100' src={`/img/labTests/lab-hero-${item}${isMobile ? '-mob' : ''}.jpg`} alt="heroBG" />
      </div>
    ))
  }

  const renderContent = (data) => {
    if (data.loading) {
      return <Skeleton count={10}/>;
    } else if (data.data.ParentCategoryList.length === 0) {
      return <div className='text-center my-5 w-100'><h2 className="text-info mark">No Products found !</h2></div>;
    } else {
      return data.data.ParentCategoryList.map(item => (<div key={item.Value}>{renderSlider(labData, parseInt(item.Value))}</div>))
    }
  }

  const [ searchItem, setSearchItem ] = useState({name: ''});
  const handleSearchInput = (e) => {
    const { name, value } = e.target;
    setSearchItem(pre => ({ ...pre, [name]: value }));
  }

  const getSearchItems = async (company) => {
    if (!company.EncCompanyId) return;
    setLabData(pre => ({ ...pre, loading: true }));
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Item?CID=${company.EncCompanyId}&LOCID=${company.LocationId}&SearchStr=${searchItem.name}&CategoryIdList=${investigationItem.Value}&SubCategoryIdList&MFGList&SortBy&ExcludeOutOfStock`);
    if (res.status === 200) {
      setTimeout(() => {
        setLabData(pre => ({ ...pre, loading: false, data: { ...pre.data, itemMasterCollection: res.data.itemMasterCollection }}));    // avoid updatating ParentCategoryList field.
      }, [500])
    }                                                                                                   
  }  

  const handleSearch = (e) => {
    e.preventDefault();
    getSearchItems(activeCompany)
  }
  
  return (
    <>
      <section className="">
        {mode === 'component' ? '' : <BreadCrumb data={breadCrumbData}/>}
        <div className='content pt-0'>
          {mode === 'component' ? '' : <div className='hero-carousel'>
            <HeroSlider dataList={heroCarousel()} />
          </div>}
          <div className="feat-categories main-category">
            <div className="w-100 bg-white" style={{fontSize: '1.1em', padding: '0.9em 0.8em 0.8em', marginTop: '0.5em', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 13%) 0px 1px 3px 0px, rgb(27 31 35 / 8%) 0px 0px 0px 1px'}}>
              <div className="filter-widget mb-0 w-100" >
                <div className="top-nav-search">
                  {companyTabList.data.length === 1 ? '' : <h4 style={{fontFamily: 'Poppins'}}>Select your Diagnostic Center</h4>}
                  <form className='filter-form d-flex gap-2 gap-lg-3 flex-column flex-sm-row' onSubmit={handleSearch}>
                    {companyTabList.data.length === 1 ? '' : <div className='position-relative'>
                      <select className="form-select form-control" name='filterBy' value={activeCompany.EncCompanyId} onChange={(e) => handleDropDown(e.target.value)} aria-label="Default select" id="inputSelect" style={{lineHeight: 1, minWidth: 'fit-content'}}>
                        {companyTabList.data.map(i => (<option value={i.EncCompanyId} key={i.EncCompanyId}>{i.COMPNAME}</option>))}
                      </select>
                    </div>}
                    <input className='form-control newSearchBox ms-0 ms-md-auto w-auto' type="text" name='name' value={searchItem.name} onChange={handleSearchInput} placeholder='Search Tests' style={{padding: '0em 0.9em', minWidth: '16rem'}}/>
                    <div className='d-flex justify-content-between gap-2 gap-lg-3'>
                      <button type='submit' className='dashboard-card__btn-box-item reverse-hover d-flex align-items-center icon-btn text-nowrap' style={{'--clr': '#48fffc3b', '--bg': 'var(--bg-3)', '--bClr': '#149a8d57', gap: '0.3em', fontSize: '0.8em', padding: '0.3em 0.6em 0.3em', borderRadius: '3.1em'}}><i className='bx bx-search' style={{fontSize: '1.5em'}}></i> <span>Search</span></button>
                      {/* <button type='button' onClick={() => {}} className='dashboard-card__btn-box-item reverse-hover d-flex align-items-center icon-btn text-nowrap ms-auto' style={{'--clr': '#48fffc3b', '--bg': 'var(--bg-3)', '--bClr': '#149a8d57', gap: '0.3em', fontSize: '0.8em', padding: '0.3em 0.6em 0.3em', borderRadius: '3.1em'}}><i className='bx bx-reset' style={{fontSize: '1.5em'}}></i> <span>Reset</span></button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='feat-categories feat-sub-categories pt-0' style={{background: '#f1f1f1'}}>
            <div className='pharmacy-cards overflow-hidden slider-wrapper'>
                {renderContent(labData)}
            </div> 
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToLabTests = (state) => {
  return { siteData: state.siteData, userInfo: state.userInfo, compCode: state.compCode, compInfo: state.compInfo, isMobile: state.isMobile, globalData: state.globalData }
}

const MemoizedLabTest = React.memo(LabTests);
export default connect(mapStateToLabTests, {siteDataAction, userInfoAction})(MemoizedLabTest);