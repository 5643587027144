import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DoctorCard } from "./utils/cards";
import { getFrom } from "../default/utilities";
import { ErrorCard } from "../default/cards";
import Skeleton from "react-loading-skeleton";

export const getDoctors = async (companyCode, specialistId, setState) => {                      
    if (!companyCode) return alert('no companyCode received');                  
    const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/Values?CID=${companyCode}&type=INTDOCT&prefixText=&Specialist=${specialistId}&Sdate=&Area=&Pin=&LowerFeesRange=&UpperFeesRange=`, {}, setState);
    if (res) {
        setState(res);
    } else {
        console.log('No data received');
    }
}

const Doctors = ({ compCode }) => {

    const [tabActive, setTabActive] = useState(0);

    
    const cardiologists = [
        { id: 1, name: 'Marc Parcival', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '1.jpg' },
        { id: 2, name: 'Alen Bailey', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '2.jpg' },
        { id: 3, name: 'Basil Andrew', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '3.jpg' },
        { id: 4, name: 'Edgar Denzil', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '4.jpg' },
        { id: 5, name: 'Giles Franklin', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '5.jpg' },
        { id: 6, name: 'Garfield Feris', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '6.jpg' },
    ]

    const gynaecologist = [
        { id: 6, name: 'Garfield Feris', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '6.jpg' },
        { id: 5, name: 'Giles Franklin', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '5.jpg' },
        { id: 4, name: 'Edgar Denzil', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '4.jpg' },
        { id: 3, name: 'Basil Andrew', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '3.jpg' },
        { id: 2, name: 'Alen Bailey', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '2.jpg' },
        { id: 1, name: 'Marc Parcival', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '1.jpg' },
    ]

    const neurologist = [
        { id: 4, name: 'Edgar Denzil', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '4.jpg' },
        { id: 3, name: 'Basil Andrew', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '3.jpg' },
        { id: 6, name: 'Garfield Feris', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '6.jpg' },
        { id: 1, name: 'Marc Parcival', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '1.jpg' },
        { id: 5, name: 'Giles Franklin', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '5.jpg' },
        { id: 2, name: 'Alen Bailey', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '2.jpg' },
    ]

    const ophthalmologist = [
        { id: 3, name: 'Basil Andrew', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '3.jpg' },
        { id: 1, name: 'Marc Parcival', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '1.jpg' },
        { id: 5, name: 'Giles Franklin', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '5.jpg' },
        { id: 6, name: 'Garfield Feris', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '6.jpg' },
        { id: 2, name: 'Alen Bailey', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '2.jpg' },
        { id: 4, name: 'Edgar Denzil', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '4.jpg' },
    ]

    const pediatrician = [
        { id: 6, name: 'Garfield Feris', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '6.jpg' },
        { id: 5, name: 'Giles Franklin', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '5.jpg' },
        { id: 4, name: 'Edgar Denzil', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '4.jpg' },
        { id: 3, name: 'Basil Andrew', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '3.jpg' },
        { id: 1, name: 'Marc Parcival', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '1.jpg' },
        { id: 2, name: 'Alen Bailey', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '2.jpg' },
    ]

    const genPractitioner = [
        { id: 2, name: 'Alen Bailey', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '2.jpg' },
        { id: 5, name: 'Giles Franklin', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '5.jpg' },
        { id: 6, name: 'Garfield Feris', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '6.jpg' },
        { id: 1, name: 'Marc Parcival', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '1.jpg' },
        { id: 3, name: 'Basil Andrew', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '3.jpg' },
        { id: 4, name: 'Edgar Denzil', place: 'Newyork', phone: '+321 567 89 0123', mail: 'Bailey@Hospitals.com', img: '4.jpg' },
    ]

    const [departmentData, setDepartmentData] = useState({loading: true, data: [], err: {status: false, msg: ''}});
    const [doctors, setDoctors] = useState({loading: true, data: [], err: {status: false, msg: ''}});

    const getDepartmentsList = async (companyCode) => {
        if (!companyCode) return console.log('no companyCode received');
        const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/Values?CID=${companyCode}`, {}, setDepartmentData);
        if (res) {
          setDepartmentData(res);
        }
    }
    
    useEffect(() => {
        getDoctors(compCode, 0, setDoctors);
        getDepartmentsList(compCode);
    },[])  
    
    const handleSelect = (id) => {
        getDoctors(compCode, id, setDoctors);
        setTabActive(id);
    }

    const renderDepartments = (data) => {
        if (data.loading) {
            return <div className='w-100'><Skeleton count={10}/></div>;
          } else if (data.err.status) {
            return <ErrorCard message={`An error occured, please try again later. Error code: ${data.err.msg}`} />
          } else if (data.data.length === 0) {
            return <div className='text-center my-5 w-100'><h2 className="text-info mark">No Departments found</h2></div>;
          } else {
            return data.data.map(i => (
                <li key={i.SubCode} className={tabActive === i.SubCode ? 'active' : '' }><Link to="#" onClick={() => handleSelect(i.SubCode)}>{i.Description}</Link></li>
            ))
        }
    }

    const renderDoctors = (data) => {
        if (data.loading) {
            return <div className='w-100'><Skeleton count={10}/></div>;
          } else if (data.err.status) {
            return <ErrorCard message={`An error occured, please try again later. Error code: ${data.err.msg}`} />
          } else if (data.data.length === 0) {
            return <div className='text-center my-5 w-100'><h2 className="text-info mark">No Doctors found</h2></div>;
          } else {
            return data.data.map(i => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12" key={i.specialistId}>
                    <DoctorCard data={i} specialistId={i.SpecialistId} />
                </div>
            ))
        }
    }

    // const getCategoryDoctor = () => {
    //     if (!doctors.data.length) return [];
    //     let catDoctors = doctors.data.filter(i => i.SubCode === tabActive);
    //     console.log(catDoctors);    
    //     return catDoctors.length ? catDoctors : [];
    // }

    // console.log(getCategoryDoctor());
    
    return (
        <div className="bsn-global">
            <section className="breadcrumb-area" style={{backgroundImage: 'url(/assets/img/aboutUs/aboutus-background.jpg)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumbs">
                                <h1>Our Doctors</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="left">
                                    <ul>
                                        <li><a href="index.html">Home</a></li>
                                        <li><span className="material-symbols-outlined">navigate_next</span></li>
                                        <li className="active">Our Doctors</li>
                                    </ul>
                                </div>
                                <div className="right">
                                    <a href="#"><span className="material-symbols-outlined">share</span> Share</a> 
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team-area doctor doctor-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3" style={{fontSize: '0.85em', maxHeight: '90vh', overflow: 'auto'}}>
                            <ul className="nav nav-tabs tab-menu">
                                {renderDepartments(departmentData)}
                                {/* <li className={tabActive === 'cardiologist' ? 'active' : '' }><Link to="#" onClick={() => setTabActive('cardiologist')}>Cardiologist</Link></li>
                                <li className={tabActive === 'gynaecologist' ? 'active' : '' }><Link to="#" onClick={() => setTabActive('gynaecologist')}>Gynaecologist</Link></li>
                                <li className={tabActive === 'neurologist' ? 'active' : '' }><Link to="#" onClick={() => setTabActive('neurologist')}>Neurologist</Link></li>
                                <li className={tabActive === 'ophthalmologist' ? 'active' : '' }><Link to="#" onClick={() => setTabActive('ophthalmologist')}>Ophthalmologist</Link></li>
                                <li className={tabActive === 'paediatrician' ? 'active' : '' }><Link to="#" onClick={() => setTabActive('paediatrician')}>Paediatrician</Link></li>
                                <li className={tabActive === 'practitioner' ? 'active' : '' }><Link to="#" onClick={() => setTabActive('practitioner')}>General Practitioner</Link></li> */}
                            </ul>   
                        </div>
                        <div className="col-md-9">
                            <div className="tab-content">
                                <div className={`tab-pane active`}>
                                    <div className="row">
                                        {renderDoctors(doctors)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Doctors;