import { breadCrumbAction } from "../../../../actions";
import { useEffect } from "react";
import { connect } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { scrollWithOffset } from "../utilities";
import { TAKE_HOME_ID, XYZ_ID } from "../../../../constants";

const ReturnPolicy = ({ breadCrumbAction, compCode }) => {

    useEffect(() => {
		breadCrumbAction({links: [{name: 'Home', link: '/'}, {name: 'Return Policy', link: '/returnPolicy'}], activeLink: '/returnPolicy'});
    },[breadCrumbAction])  

    if (compCode === TAKE_HOME_ID || compCode === XYZ_ID) return (<div className="container"><h1>Coming soon..</h1></div>);
    return (
        <section id="return-policy" className='terms-conditions-styles'>
            <div className="container">
                <div>
                    <div id="return-policy" className="content" style={{background: 'white'}}>
                    <div className="last-updated mb-20">
                        <span>last updated May 2023</span>
                    </div>
                    <div className="">
                        <h1 className="heading">E-Pharma Return &amp; Refund Policy</h1>
                        <p className="sub-heading">
                        E-Pharma RETURN POLICY, REFUND, CANCELLATION AND SHIPPING CHARGES POLICY
                        <br />
                        <br /> E-Pharma Healthcare Solutions Private Limited (“E-Pharma”) team
                        facilitates processing correct medicines as per order and prescription
                        and strives to service the medicines and products in right conditions/
                        without any damage every time a consumer places an order. We also
                        strongly recommend the items are checked at the time of delivery.
                        </p>
                        <h3 className="table-content">Table of Content</h3>
                        <ul className="main-contents" style={{paddingLeft: '2rem'}}>
                        <li>
                            <HashLink to="#DEFINITION" scroll={el => scrollWithOffset(el, 120)}>
                            <span>DEFINITION</span>
                            </HashLink>
                        </li>
                        <li>
                            <HashLink to="#RETURNPROCESS" scroll={el => scrollWithOffset(el, 120)}>
                            <span>RETURN PROCESS</span>
                            </HashLink>
                        </li>
                        <li>
                            <HashLink to="#MEDICALTEST" scroll={el => scrollWithOffset(el, 120)}>
                            <span>MEDICAL TEST</span>
                            </HashLink>
                        </li>
                        <li>
                            <HashLink to="#REFUNDPROCESS" scroll={el => scrollWithOffset(el, 120)}>
                            <span>REFUND PROCESS</span>
                            </HashLink>
                        </li>
                        <li>
                            <HashLink to="#ONLINECONSULTATION" scroll={el => scrollWithOffset(el, 120)}>
                            <span>ONLINE CONSULTATION</span>
                            </HashLink>
                        </li>
                        <li>
                            <HashLink to="#SHIPPINGCHARGES" scroll={el => scrollWithOffset(el, 120)}>
                            <span>SHIPPING CHARGES</span>
                            </HashLink>
                        </li>
                        <li>
                            <HashLink to="#SHIPPINGCHARGES" scroll={el => scrollWithOffset(el, 120)}>
                            <span>CANCELLATION POLICY</span>
                            </HashLink>
                        </li>
                        </ul>
                        <ol className="root-list">
                        <li>
                            <div>
                            <div id="DEFINITION" className="cont-details">
                                <h2>DEFINITION</h2>
                                'Return' means an action of giving back the product ordered at
                                E-Pharma portal by the consumer. The following situations may arise
                                which may cause the action of return of product:
                                <ol className="sub-norm">
                                <li> Product(s) delivered do not match your order; </li>
                                <li>
                                    {" "}
                                    Product(s) delivered are past or near to its expiry date
                                    (medicines with an expiry date of less than 03 months shall
                                    be considered as near expiry);{" "}
                                </li>
                                <li>
                                    {" "}
                                    Product(s) delivered were damaged in transit (do not to
                                    accept any product which has a tampered seal):{" "}
                                </li>
                                </ol>
                            </div>
                            <div className="sub-heading">
                                {" "}
                                Note: If the product that you have received is damaged, then do
                                not accept the delivery of that product. If after opening the
                                package you discover that the product is damaged, the same may
                                be returned for a refund. Please note that we cannot promise a
                                replacement for all products as it will depend on the
                                availability of the particular product, in such cases we will
                                offer a refund.{" "}
                            </div>
                            <div>
                                {" "}
                                In the aforesaid unlikely situations, if there is something
                                wrong with the order, we'd be happy to assist and resolve your
                                concern. You may raise a Return request with our customer care
                                within 07 (Seven) days from the delivery of the product. E-Pharma
                                reserves the right to cancel the Return request, if the customer
                                reaches out to E-Pharma after 7 days of delivery.{" "}
                            </div>
                            <div>
                                {" "}
                                Upon receiving your Return/Refund request, E-Pharma shall verify the
                                authenticity and the nature of the request. If E-Pharma finds that
                                the request is genuine, it will initiate the Return and Refund
                                process. E-Pharma shall process the refund only once it has received
                                the confirmation from the vendor concerned in respect of the
                                contents of the product relating to that refund.{" "}
                            </div>
                            <div>
                                {" "}
                                In the event of frivolous and unjustified complaints regarding
                                the quality and content of the products, E-Pharma reserves the right
                                to pursue necessary legal actions against you and you will be
                                solely liable for all costs incurred by E-Pharma in this regard.{" "}
                            </div>
                            <h4>The returns are subject to the below conditions:-</h4>
                            <ol>
                                <li>
                                {" "}
                                Any wrong ordering of product doesn’t qualify for Return;{" "}
                                </li>
                                <li>
                                {" "}
                                Batch number of the product being returned should match as
                                mentioned on the invoice;{" "}
                                </li>
                                <li>
                                {" "}
                                Return requests arising due to change in prescription do not
                                qualify for Return;{" "}
                                </li>
                                <li>
                                {" "}
                                Product being returned should only be in their original
                                manufacturer's packaging i.e. with original price tags,
                                labels, bar-code and invoice; and{" "}
                                </li>
                                <li>
                                {" "}
                                Partially consumed strips or products do not qualify for
                                Return, only fully unopened strips or products can be
                                returned.{" "}
                                </li>
                            </ol>
                            <p>
                                {" "}
                                <u>Category of Non-Returnable Product:</u>
                                <span>
                                <span>
                                    Certain categories of products marked as non- returnable on
                                    product page, will not qualify for the Return as per E-Pharma
                                    Return policy. The details of the non- returnable products
                                    are mentioned below:{" "}
                                </span>
                                </span>
                            </p>
                            <table>
                                <thead>
                                <tr>
                                    <th>Categories</th>
                                    <th>Type of Products</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Baby Care</td>
                                    <td>
                                    Bottle Nipples, Breast Nipple Care, Breast Pumps, Diapers,
                                    Ear Syringes, Nappy, Wet Reminder, Wipes and Wipe Warmers
                                    </td>
                                </tr>
                                <tr>
                                    <td>Food and Nutrition</td>
                                    <td>Health Drinks, Health Supplements</td>
                                </tr>
                                <tr>
                                    <td>Healthcare Devices</td>
                                    <td>
                                    Glucometer Lancet/Strip, Healthcare Devices and Kits,
                                    Surgical, Health Monitors
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sexual Wellness</td>
                                    <td>
                                    Condoms, Fertility Kit/Supplement, Lubricants, Pregnancy
                                    Kits
                                    </td>
                                </tr>
                                <tr>
                                    <td>Temperature Controlled and Speciality Medicines</td>
                                    <td>
                                    Vials, Injections, Vaccines, Penfills and any other
                                    Product, requiring cold storage, or medicines that fall
                                    under the category of speciality medicines.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </li>
                        <li>
                            <div id="RETURNPROCESS" className="cont-details">
                            <h2>RETURN PROCESS:</h2>
                            <ol>
                                <li>
                                For Return intimation, please visit{" "}
                                <a href="https://www.E-Pharma.com/contactUs" target="_black">
                                    www.E-Pharma.com/contactUs
                                </a>
                                .
                                </li>
                                <li>
                                E-Pharma customer care team will verify the claim made by the
                                customer within 72 (seventy-two) business hours from the time
                                of receipt of complaint.
                                </li>
                                <li>
                                Once the claim is verified as genuine and reasonable, E-Pharma will
                                initiate the collection of product(s) to be returned.
                                </li>
                                <li>
                                The customer will be required to pack the product(s) in
                                original manufacturer’s packaging.{" "}
                                </li>
                                <li>
                                Refund will be completed within 30 (thirty) days from date of
                                reverse pick up (if required).{" "}
                                </li>
                            </ol>
                            </div>
                        </li>
                        <li>
                            <div id="MEDICALTEST" className="cont-details">
                            <h2>MEDICAL TEST:</h2>
                            <div>
                                In case of medical tests, a Refund request may be raised in the
                                following cases:
                            </div>
                            <ol>
                                <li>
                                If the patient has suffered from Haematoma or any prick
                                related injury;
                                </li>
                                <li>
                                If the report has been challenged and no proper justification
                                (
                                <i>
                                    i.e. reasonable clarification provided either by E-Pharma or the
                                    diagnostic centre
                                </i>
                                ) has been provided;{" "}
                                </li>
                                <li>
                                If the time limit within which a report has to be provided to
                                the patient is breached by 72 (seventy-two) hours and no
                                proper justification (
                                <i>
                                    i.e reasonable clarification provided either by E-Pharma or the
                                    diagnostic centre
                                </i>
                                ) has been provided.
                                </li>
                            </ol>
                            </div>
                        </li>
                        <li>
                            <div id="REFUNDPROCESS" className="cont-details">
                            <h2>REFUND PROCESS:</h2>
                            <div>
                                In all the above cases, if the claim is found to be valid,
                                Refund will be made as mentioned below:
                            </div>
                            <ol>
                                <li>
                                Order placed through online wallet will be credited to the
                                wallet; and
                                </li>
                                <li>
                                Order placed through cash on delivery will be refunded through
                                fund transfer to customer bank account.
                                </li>
                            </ol>
                            </div>
                        </li>
                        <li>
                            <div id="ONLINECONSULTATION" className="cont-details">
                            <h2>ONLINE CONSULTATION:</h2>
                            <p>
                                {" "}
                                In case of online consultation, a customer will be eligible to
                                raise a request for Refund only in case the consultation query
                                is not replied within specified timeline.{" "}
                            </p>
                            <p>
                                {" "}
                                The customer is required to raise the Refund request with E-Pharma
                                customer care within 72 (seventy-two) hours from the time of
                                submission of query or receiving of response. The request for
                                the Refund will be validated by E-Pharma customer care team.{" "}
                            </p>
                            <p>
                                {" "}
                                In case of valid Refund, the same will be done by crediting the
                                bank account of the customer. Refund process shall be completed
                                within 30 (thirty) days from the date of submission of the
                                request for Refund.{" "}
                            </p>
                            </div>
                        </li>
                        <li>
                            <div id="SHIPPINGCHARGES" className="cont-details">
                            <h2>SHIPPING CHARGES</h2>
                            <p>
                                {" "}
                                Estimated shipping charges are calculated as per the value of
                                the order and can be viewed in the cart section at the time of
                                checkout. For any further shipping related information, please
                                write to <a href="mailto:care@E-Pharma.com">care@E-Pharma.com​.</a>{" "}
                            </p>
                            <p>
                                {" "}
                                For any further Refund related information, please write to{" "}
                                <a href="mailto:care@E-Pharma.com">care@E-Pharma.com​.</a>{" "}
                            </p>
                            </div>
                        </li>
                        <li>
                            <div id="CANCELLATIONPOLICY" className="cont-details">
                            <h2>CANCELLATION POLICY</h2>
                            <p>
                                {" "}
                                <b>Customer cancellation:</b>{" "}
                            </p>
                            <p>
                                {" "}
                                The customer can cancel the order for the product till E-Pharma ship
                                it. Orders once shipped cannot be cancelled.{" "}
                            </p>
                            <p>
                                {" "}
                                The customer can cancel the order for medical test till the
                                collection of sample.{" "}
                            </p>
                            <p>
                                {" "}
                                <b>E-Pharma cancellation:</b>{" "}
                            </p>
                            <p>
                                {" "}
                                There may be certain orders that E-Pharma partners are unable to
                                accept and service and these may need to be cancelled. Some
                                situations that may result in your order being cancelled
                                include, non-availability of the product or quantities ordered
                                by you or inaccuracies or errors in pricing information
                                specified by our partners.{" "}
                            </p>
                            <p>
                                {" "}
                                E-Pharma also reserves the right to cancel any orders that classify
                                as ‘Bulk Order’ as determined by E-Pharma as per certain criteria. An
                                order can be classified as ‘Bulk Order’ if it meets with the
                                below mentioned criteria, which may not be exhaustive, viz:{" "}
                            </p>
                            <p>
                                {" "}
                                i. Products ordered are not for self-consumption but for
                                commercial resale;{" "}
                            </p>
                            <p>
                                {" "}
                                ii. Multiple orders placed for same product at the same address;{" "}
                            </p>
                            <p> iii. Bulk quantity of the same product ordered; </p>
                            <p> iv. Invalid address given in order details; </p>
                            <p> v. Any malpractice used to place the order. </p>
                            <p>
                                No cancellation charges shall be levied for cancellation of an
                                order in accordance with the terms of this policy.
                            </p>
                            </div>
                        </li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToReturnPolicy = (state) => {
    return { compCode: state.compCode };
}
  
export default connect(mapStateToReturnPolicy, {breadCrumbAction})(ReturnPolicy)