import React from 'react';
import RenderCarousel from "../../carousel";

function SliderSection({ children, data, className='' }) {

  const items = data.map(item => {
    return (
      <div key={item.ItemId} className={className} style={{"display": "grid", "placeItems": "center"}}>
          {React.cloneElement(children, { data: item, key: item.ItemId })}
      </div>      
    )
  })

  const responsive = {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			768: {
				items: 3
			},
			992: {
				items: 4
			},
			1200: {
				items: 5
			}
		}
  
  if (items.length === 0) return;

  return (
    <>
      <RenderCarousel
        data={items} 
        responsive={responsive}
        autoPlay={false}
        stopAutoPlayOnHover={true}
        infinite={true}
        autoPlayInterval={1500} 
      />
    </>
  );
}

export default SliderSection;
