import { ControlledCarousel } from "../default/utilities";

const Hero = () => {

    const images = [
        'img/bg/newLife/NewLif1.jpeg',
        'img/bg/newLife/NewLif2.jpeg',
        'img/bg/newLife/NewLif3.jpeg',
    ];

    return (
        <div className='row am-nursing-home'>
            <div className='col col-12 col-lg-6 order-2 order-lg-1'>
                <div className="choose-us-content">
                    <span className="text-uppercase text-sm letter-spacing" style={{fontWeight: 600, color: 'rgb(255 69 0)'}}>WHY CHOOSE US</span>
                    <h2>Our hospital has professional doctors who provide low cost 24 hour service</h2>
                    <ul>
                        <li>
                            <span>1</span>
                            <div>
                                <h3>Modern Technology</h3>
                                <p>Pellentesque in ipsum id orci porta dapibus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            </div>
                        </li>
                        <li className="active">
                            <span>2</span>
                            <div>
                                <h3>Professional Doctors</h3>
                                <p>Pellentesque in ipsum id orci porta dapibus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            </div>
                        </li>
                        <li>
                            <span>3</span>
                            <div>
                                <h3>Affordable Price</h3>
                                <p>Pellentesque in ipsum id orci porta dapibus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                </div>
                <div className='col col-12 col-lg-6 order-1 order-lg-2 position-relative'>
                <div className='img-box'>
                    <img className='img-fluid' src="/img/choose-us-img.jpg" alt="choose-us"/>                    
                    {/* <ControlledCarousel data={images} interval={2000} controls={false}/> */}
                    <div className='contact-lable'>
                        <i className='bx bxs-ambulance'></i>
                        <div>
                            <p>24/7 Hours Service</p>
                            <h2>1800 123 763</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;