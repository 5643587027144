import { modalAction } from "../../../actions";
import { LoginAlert, ModalComponent, MyAlert, MyModal } from "./utilities";
import { connect } from "react-redux";

import CategoriesModal from './modals/categoriesModal';
import LoginModal from "./modals/loginModal";
import QuickViewModal from './modals/quickviewModal';
import UserInfoModal from './modals/userInfoModal';
import OrderSuccessModal from "./modals/orderSuccessModal";
import OrderCancelledModal from "./modals/orderCancelledModal";
import EditUserModal from "./modals/editUserModal";
import ReturnPolicy from "./modals/policyModals/returnPolicy";
import PrescriptionUpload from "./modals/prescriptionUpload";
import LocationsModal from "./modals/locationsModal";

const Modals = ({ modals, modalAction }) => {
    
    const handleAlertModal = () => {
        modalAction('ALERT_MODAL', false);
    }
    const handlePrescModal = () => {
        modalAction('PRESCRIPTION_MODAL', false);
    }
    return (
        <>
            <ModalComponent isActive={modals.QUICKVIEW_MODAL.state} child={<QuickViewModal/>}/>       {/* */}               
            <ModalComponent isActive={modals.CATEGORY_MODAL.state} child={<CategoriesModal/>}/>       {/* */}
            <ModalComponent isActive={modals.ORDER_SUCCESS_MODAL.state} child={<OrderSuccessModal/>}/>       {/* */}
            <ModalComponent isActive={modals.ORDER_CANCELLED_MODAL.state} child={<OrderCancelledModal/>}/>       {/* */}
            <ModalComponent isActive={modals.RETURN_POLICY_MODAL.state} child={<ReturnPolicy/>}/>       {/* */}
            <ModalComponent isActive={modals.LOCATION_MODAL.state} className={'location-selection-modal'} child={<LocationsModal />}/>

            {modals.LOGIN_MODAL.state && <MyModal name='LOGIN_MODAL' handleClose={modalAction} customClass='login-modal' child={<LoginModal />} closeIcon={false} />}       {/* */}
            {modals.USER_INFO_MODAL.state && <MyModal name='USER_INFO_MODAL' handleClose={modalAction} customClass='' child={<UserInfoModal />} closeIcon={false} />}       {/* */}
            {modals.EDIT_USER_MODAL.state && <MyModal name='EDIT_USER_MODAL' handleClose={modalAction} customClass='login-modal edit-user' child={<EditUserModal />} closeIcon={false} />}       {/* */}
            {modals.ALERT_MODAL.state && <MyModal name='local-handler' handleClose={handleAlertModal} customClass='my-alert' styles={{maxWidth: '28em'}} interval={1750} child={<LoginAlert type={modals.ALERT_MODAL.data} />} />}
            {modals.PRESCRIPTION_MODAL.state && <MyModal name='local-handler' handleClose={handlePrescModal} customClass='presc-modal' styles={{padding: 0, maxWidth: '60em'}} child={<PrescriptionUpload />} />}
        </>
    )
}

const mapStateToModals = (state) => {
    return { modals: state.modals };
}

export default connect(mapStateToModals, {modalAction})(Modals);

// -----------------------------------------------------------------------------------------------------------------------

